import * as React from 'react';
import {styled} from '@mui/material/styles';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    textAlign: 'left',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },

    // todo fontFamily: 'Fauna One',
    // todo rounded corners
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowDropDownIcon sx={{ fontSize: '2rem' }}/>}
        // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    background: "var(--main-bg-color);",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    background: "var(--main-bg-color);",
    paddingLeft: theme.spacing(3),
    textAlign: 'left',
    color: "var(--grey-color)",
}));

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState<string | false>('panel0');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <>
            {/*<div style={{width: "10%"}}></div>*/}
            {/*<div style={{width: "80%", left:"20%"}}>*/}

                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Cum aplic la un club RaiseAReader?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Dacă grădinița ta are deja un parteneriat cu RaiseAReader, aplicarea e simplă! Completezi formularul prin care soliciți aderarea la unul dintre cluburile pentru lectură în familie din grădinița ta. Dacă grădinița ta nu are încă un parteneriat cu Raise a Reader, trebuie să aplici pentru înființarea unui club în grădinița copilului tău, iar noi vom contacta grădinița pentru a demara parteneriatul cu ea.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Pot să aplic la un club RaiseAReader dacă grădinița copilului meu nu are un parteneriat Raise A Reader?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Un club pentru lectură în familie se formează atunci când minim 4 familii se unesc pentru a împărți costurile și beneficiile unui abonament. Cluburile RaiseAReader funcționează doar în cadrul grădinițelor. Dacă grădinița ta nu face parte din programul Raise a Reader, poți oricând să aplici pentru un abonament online simplu, pentru a avea acces la toate materialele de pe platformă.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Ce include un abonament RaiseAReader?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Abonamentul Kindergarten include: un pachet de cărți cu care să participi în clubul din grădiniță, acces la platforma online unde găsești toate materialele de suport pentru a încuraja lectura interactivă. În abonament este inclus și managmentul schimburilor între membrii clubului.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                        <Typography>Ce se întâmplă dacă am deja în bibliotecă o carte din planul lunar?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Dacă în pachetul inițial pe care îl primești la începutul abonamentului se găsesc cărți pe care le ai deja în bibliotecă, înlocuim cărțile cu alte titluri.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                        <Typography>Ce se întâmplă dacă o carte se uzează și nu mai poate fi folosită?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Dacă uzura este minimă, încurajăm repararea cărților acasă. Dacă însă cartea nu mai poate fi utilizată, abonamentul include o înlocuire gratuită pentru o carte afectată. Celelalte cărți afectate vor trebui înlocuite cu costuri adiționale.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                        <Typography>Cum selectați cărțile?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Planurile de lectură sunt create cu ajutorul specialiștilor în literație timpurie, educatori și părinți. Selecția se face în primul rând în funcție de categoria de vârstă. Alegem cărți cu mesaje, texte și imagini de calitate, cu valoare estetică și educativă. Actualizăm mereu colecția de cărți, incluzând titluri noi și ținând cont de recomandările pe care le primim de la utilizatori.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary aria-controls="panel7d-content" id="panel7d-header">
                        <Typography>De ce ar trebui să am un plan de lectură?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Dacă scopul tău e să crești un cititor încrezător și entuziast, un plan de lectură te ajută să fii consistent în ceea ce ți-ai propus. "A goal without a plan is just a wish", așa cum zice celebrul citat al lui Antoine de Saiunt-Exupéry, iar noi te ajutăm să transformi scopul în realitate.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary aria-controls="panel8d-content" id="panel8d-header">
                        <Typography>Aveți și cărți în altă limbă?</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Momentan avem cărți în română și engleză. La cererea cititorilor noștri putem să adăugăm cărți în limba franceză, precum și în germană și maghiară.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                {/*<Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>*/}
                {/*    <AccordionSummary aria-controls="panel9d-content" id="panel9d-header">*/}
                {/*        <Typography>Pot să anulez abonamentul?</Typography>*/}
                {/*    </AccordionSummary>*/}
                {/*    <AccordionDetails>*/}
                {/*        <Typography>*/}
                {/*            Planurile pentru grădinițe sunt făcute în funcție de numărul de persoane din grup, deci anularea unui abonament influențează toată micro-biblioteca. Putem să anulăm cu 0 impact pentru grup, dacă un alt părinte intră în grupul deja format. Excepțiile le vom trata individual, în funcție de situație.*/}
                {/*        </Typography>*/}
                {/*    </AccordionDetails>*/}
                {/*</Accordion>*/}
                {/*<Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>*/}
                {/*    <AccordionSummary aria-controls="panel10d-content" id="panel10d-header">*/}
                {/*        <Typography>Cum plătesc abonamentul?</Typography>*/}
                {/*    </AccordionSummary>*/}
                {/*    <AccordionDetails>*/}
                {/*        <Typography>*/}
                {/*            Plata se face online, cu cardul introdus la crearea micro-bibliotecii în contul individual. În următoarele luni, nu trebuie să faci nimic, plățile se vor face automat lună de lună.*/}
                {/*        </Typography>*/}
                {/*    </AccordionDetails>*/}
                {/*</Accordion>*/}
                {/*<Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>*/}
                {/*    <AccordionSummary aria-controls="panel11d-content" id="panel11d-header">*/}
                {/*        <Typography>Sunt datele cardului meu în siguranță?</Typography>*/}
                {/*    </AccordionSummary>*/}
                {/*    <AccordionDetails>*/}
                {/*        <Typography>*/}
                {/*            Procesăm plățile prin Banca Transilvania. Datele tale sunt în deplină siguranță și nimeni nu are acces la cardul tău.*/}
                {/*        </Typography>*/}
                {/*    </AccordionDetails>*/}
                {/*</Accordion>*/}
        </>
    );
}
