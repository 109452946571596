import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../App.css';
import {Box, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import {Link, useParams} from "react-router-dom";
import {IBookThumbnail} from "../services/model/Books";
import {getBookPackages, getFilteredBooks, getPublicBooks} from "../services/booksService";
import RegisterSection from "../core/components/RegisterSection";
import {LibraryBooksRounded, Park} from "@mui/icons-material";
import {Button} from 'react-bootstrap'
import AddToBagComponent from "../core/components/AddToBag";
import {BookPackage} from "../services/model/Packages";
import {Helmet} from "react-helmet-async";

function Packages() {
    const {filter, slug} = useParams();
    const observer = useRef<IntersectionObserver>();

    const [packages, setPackages] = useState<BookPackage[]>([]);
    const [totalNumber, setTotalNumber] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChristmasCampaign, setIsChristmasCampaign] = useState<boolean>(window.location.href.includes('/campaign/christmas'));


    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    let itemsPerRow = isSm || isXs ? 2 : 4;

    const lastElementRef = useCallback(
        (node: HTMLDivElement) => {
            console.log("lastElementRef", node);
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && page < totalPages ) {
                    console.log("entries", entries[0]);
                        setPage(page + 1);
                        console.log("pageInRef: ",page);

                        getData(page, size)
                } else {
                    console.log("not visible", entries[0]);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading]
    );

    const getData = useCallback(async (page:number, size:number) => {
        setIsLoading(true);
        try {
            var pagedPackages;
            pagedPackages = await getBookPackages(page, size);
            if (page === 0) {
                packages.splice(0, packages.length);
            }
            pagedPackages.data.content.forEach((pack: BookPackage) => {packages.push(pack)});
            setTotalPages(pagedPackages.data.totalPages)
            setTotalNumber(pagedPackages.data.totalElements);

        } catch (e) {
                console.error(e)
        } finally {
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        getData(page, size);
    },[])

    return (
      <>
          <Helmet>
              <title>Pachete de cărți pentru copii – Raise a Reader</title>
              <meta
                  name="description"
                  content="Descoperă pachetele de cărți create special pentru copii: pregătire pentru grădiniță sau școală, olita, emoții și teme esențiale pentru dezvoltarea armonioasă."
              />
              <link rel="canonical" href="https://www.raiseareader.ro/packages/all" />
          </Helmet>
          <div className="App">
              <div id="wave" className="parent align-left top-section div-with-background">
                  <div className="small-container ">
                      <br/>
                     <div>
                          <h2>E o zi bună pentru a descoperi cărți grozave!</h2><br/>
                          <h5>Am creat <span className="low-highlight-purple">pachete tematice</span> care te ajută să alegi mai ușor:
                              cărți despre grădiniță, școală, emoții și alte teme importante pentru dezvoltarea copiilor.
                              Fiecare pachet include titluri alese cu grijă pentru a transforma lectura într-o experiență valoroasă.
                          </h5>
                      </div>
                  </div>
              </div>
              <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
              <div className="App-body fauna-one-regular">

                  <Grid container spacing={2}>
                      {packages
                          .filter((pack) => pack.id !== null)
                          .map((pack, index) => {
                                  return (
                                          <Grid item xs={6} sm={6} md={3} lg={3} key={pack.id}>
                                                  {/*<Box>*/}
                                              <div
                                                  ref={!isChristmasCampaign ? lastElementRef : null}
                                                  style={{display: "flex", flexDirection: "column", height: "100%"}}>
                                                  <Link to={`/packages/details/` + pack.slug}>
                                                      {pack.photos?.[0].url ? (
                                                          <Box key={pack.photos?.[0].url} sx={{
                                                              marginBottom: 2,
                                                              filter: 'drop-shadow(rgba(205, 180, 219, 0.5) 20px 30px 10px)',
                                                              display: 'block'
                                                          }}>
                                                              <img className='bookInPlan' src={pack.photos?.[0].url}
                                                                   alt={pack.name} style={{
                                                                  maxHeight: '210px',
                                                                  maxWidth: '250px',
                                                                  objectFit: 'cover',
                                                                  minHeight: '200px'
                                                              }}/>
                                                          </Box>

                                                      ) : (
                                                          <Typography variant="body2">No photos
                                                              available</Typography>
                                                      )}
                                                  </Link>

                                                  <div className="fauna-one-regular"
                                                       style={{
                                                           fontSize: '.75em',
                                                           opacity: '.7',
                                                           paddingBottom: '5px'
                                                       }}
                                                  >
                                                      PACHET COPII {pack.ageGroup.startAge === 0 ? '6+ LUNI' : `${pack.ageGroup.startAge}+ ANI`}
                                                  </div>
                                                  <div className="fauna-one-regular"
                                                       style={{
                                                           fontSize: '14px'
                                                       }}
                                                  >
                                                          {pack.name}
                                                  </div>

                                                  <AddToBagComponent isDetailsPage={false} pack={pack} />
                                              </div>
                                              {/*</Box>*/}
                                          </Grid>
                                      )
                                      }
                                  )}
                  </Grid>
                  {/*</Box>*/}
                  {/*</div>*/}
              </div>
              {isChristmasCampaign ?
                  <Button className="button-contact">
                      <Link to={"/christmas-packs"} style={{color: "white"}}>
                          <Park sx={{color: "var(--button-hover-background)"}}/>
                          Achiziționează acum un pachet
                          <Park sx={{color: "var(--button-hover-background)"}}/>
                          </Link>
                      </Button>
                  :
                  <div style={{marginTop: "100px"}}>
                    <RegisterSection/>
                  </div>
              }
          </div>

      </>
    );
}

export default Packages;
