import React, {useCallback, useEffect, useState} from 'react';
import '../App.css';
import {useParams} from "react-router-dom";
import {getExchanges} from "../services/teacherService";
import {Exchange, ExchangeSchedules} from "../services/model/GroupExchangeResponse";
import ReadingGroupExchangeElement from "./ReadingGroupExchangeElement";
import AdditionalBookExchangeElement from "./AdditionalBookExchangeElement";
import {Grid} from "@mui/material";
import Box from "@mui/material/Box";
import {Helmet} from "react-helmet-async";

function GroupDetails() {
    const {groupId} = useParams();

    const [exchange, setExchange] = useState<Exchange>();
    const [exchangeDates, setExchangeDates] = useState<ExchangeSchedules>();

    const getData = useCallback(async () => {
        try {
            const exchangesResponse = await getExchanges(groupId!);
            setExchange(exchangesResponse.data);
            setExchangeDates({
                previousSchedule: exchangesResponse.data.previousSchedule,
                currentSchedule: exchangesResponse.data.currentSchedule,
                nextSchedule: exchangesResponse.data.nextSchedule,
            })
        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        getData();

    }, [])

    return (
        <>
            <Helmet>
                <title>Detalii grup de lectură – Raise a Reader</title>
                <meta
                    name="description"
                    content="Vizualizați planurile de lectură și membrii asociați acestui grup din cadrul grădiniței dumneavoastră."
                />
                <link rel="canonical" href="https://www.raiseareader.ro/group-details" />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <Box className="App">
                <div id="wave" className="parent align-left top-section div-with-background">
                    <div className="small-container ">
                        <br/>
                        <h1>Planul grupului {exchange?.institutionGroupName}</h1>
                        <h5>Aici poți să vezi cărtile clubului.</h5>
                        <h5>De asemenea, poți să vezi când are loc fiecare schimb și ce cărți corespund fiecărui membru în fiecare perioadă.</h5>
                    </div>
                </div>
                <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
                <div className="App-body fauna-one-regular">
                    <div className="fauna-one-regular">
                        {exchange?.groups
                            .map((group, index) => {
                                    return (
                                        <ReadingGroupExchangeElement group={group} exchangeSchedules={exchangeDates!}/>
                                    )
                                }
                            )}
                    </div>
                    {exchange?.additionalBooks && exchange?.additionalBooks.length > 0 ?
                        <div className="fauna-one-regular">
                            <Box id="sized-container" style={{marginBottom: "10px"}} border={4}
                                 borderColor={exchange?.additionalGroupColor} borderRadius="15px">
                                <h4 style={{color: exchange?.additionalGroupColor}}><strong>Cărți adiționale</strong>
                                </h4>

                                <Grid container spacing={2}>
                                    {exchange?.additionalBooks
                                        .map((additionalBook, index1) => {
                                                return (
                                                    <AdditionalBookExchangeElement additionalBook={additionalBook}
                                                                                   exchangeSchedules={exchangeDates!}
                                                                                   additionalGroupColor={exchange?.additionalGroupColor}/>
                                                )
                                            }
                                        )}
                                </Grid>
                            </Box>

                        </div>
                        :
                        <></>
                    }

                </div>
            </Box>

        </>
    );
}

export default GroupDetails;