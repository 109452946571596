import React, {useEffect} from 'react';
import '../App.css';
import {Helmet} from "react-helmet-async";

function Terms() {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <>
            <Helmet>
                <title>Termeni și condiții – Raise a Reader</title>
                <meta
                    name="description"
                    content="Citește termenii și condițiile de utilizare pentru website-ul Raise a Reader. Informații despre drepturile, responsabilitățile și utilizarea serviciilor oferite."
                />
                <link rel="canonical" href="https://www.raiseareader.ro/terms" />
            </Helmet>
            <div className="App">
                <div id="wave" className="parent align-left top-section div-with-background">
                    <div className="small-container ">
                        <br/>
                        <h1>Termeni și Condiții</h1><br/>
                    </div>
                </div>
                <div className="gradient-wave-bg" style={{marginTop: '-40px'}}></div>
                <div className="App-body fauna-one-regular">
                    <div className="fauna-one-regular align-left">
                        <h5><strong>1. INTRODUCERE</strong></h5>
                        <p>Platforma <a href="http://www.raiseareader.ro">www.raiseareader.ro</a> este operată
                            de <strong>INFINITETECH SOLUTIONS SRL</strong>, cu sediul în Chinteni, Str. Decebal, nr. 4,
                            jud. Cluj, înregistrată la Registrul Comerțului sub numărul <strong>J12/4151/2023</strong>,
                            având Codul Unic de Înregistrare <strong>48880074</strong>, contul bancar
                            <strong>RO40BTRLRONCRT0CO3613201</strong> deschis la Banca Transilvania în calitate de
                            titular
                            și deținător al Platformei, denumită în continuare <strong>„Deținătorul
                                platformei”.</strong></p>
                        <p> Accesând, navigând sau utilizând platforma
                            <a href="http://www.raiseareader.ro">www.raiseareader.ro</a> (<strong>„Platforma”</strong>),
                            dumneavoastră (<strong>„Utilizatorul”</strong>) sunteți de acord cu acești termeni și
                            condiții
                            de utilizare. Dacă efectuați plăți online prin intermediul platformei, sunteți de asemenea
                            de
                            acord cu termenii specifici aplicați pentru aceste tranzacții. Acești termeni se aplică, de
                            asemenea, serviciilor educaționale și resurselor digitale oferite prin intermediul
                            Platformei, inclusiv seturile de cărți, ghidurile de lectură și materialele educaționale
                            suplimentare („<strong>Materiale Educative</strong>”).</p>
                        <p> Prin intermediul platformei, Deținătorul asigură accesul Utilizatorilor la o gamă variată
                            de resurse destinate părinților, pentru a sprijini dezvoltarea obiceiurilor de lectură
                            la copiii de vârstă preșcolară. Aceste resurse includ seturi personalizate de cărți și
                            materiale educative suplimentare, disponibile pe bază de abonament sau prin achiziții
                            unice.</p>
                        <p> Acești Termeni și Condiții vi se aplică imediat după accesarea pentru prima dată a
                            Platformei Raise A Reader și se consideră că ați fost de acord să vă obligați în
                            conformitate cu aceștia ca urmare a acestei prime accesări. Când sunt acceptați de
                            dvs., Termenii și Condițiile reprezintă un contract între dvs. și Deținătorul Platformei.
                            Dacă nu doriți să continuați să fiți obligați de acești Termeni și Condiții Generale,
                            vă rugăm să încetați acum utilizarea Platformei Raise a Reader.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>2. DEFINIȚII</strong></h5>
                        <p>
                            În cadrul prezentelor Termeni și Condiții vom utiliza următoarele definiții:
                            <ul>
                                <li><strong>Utilizator</strong> – orice persoană care accesează Platforma, în scopuri de
                                    informare sau utilizare a resurselor oferite, indiferent dacă achiziționează sau nu
                                    produse/servicii.
                                </li>
                                <li><strong>Client</strong>– orice persoană care achiziționează produse sau servicii
                                    prin
                                    intermediul Platformei, fie în nume propriu, fie pentru a fi oferite altor persoane
                                    (de exemplu, pentru un grup de părinți din aceeași grădiniță).
                                </li>
                                <li><strong>Cont</strong> – secțiunea personalizată a Platformei, protejată prin cont de
                                    utilizator și parolă, care permite Utilizatorului să acceseze diverse servicii,
                                    inclusiv planuri de lectură și Materiale Educative.
                                </li>
                                <li><strong>Produse și Servicii</strong> – seturile de cărți, materialele educative,
                                    abonamentele și orice alt produs sau serviciu oferit de Deținătorul platformei prin
                                    intermediul Platformei.
                                </li>
                                <li><strong>Conținut</strong> – toate informațiile și materialele afișate pe Platformă,
                                    care pot fi accesate de către Utilizatori prin intermediul unui dispozitiv
                                    electronic,
                                    inclusiv dar fără a se limita la texte, imagini, ghiduri de lectură, resurse
                                    educative
                                    și descrieri de produse.
                                </li>
                                <li><strong>Grădiniță</strong> - reprezintă unitatea de învățământ preșcolar unde sunt
                                    înscriși copiii Utilizatorilor platformei Raise a Reader. În cadrul grădiniței se
                                    vor
                                    organiza cluburi pentru lectură în familie, formate de părinții copiilor înscriși, care doresc să
                                    participe la proiectul de promovare a lecturii pentru preșcolari.
                                </li>
                                <li><strong>Club pentru lectură în familie</strong> - este un grup de părinți format în cadrul unei
                                    grădinițe participante la platforma Raise a Reader. Cluburile Raise A Reader pot fi
                                    alcătuite din 4 până la 12 părinți, în funcție de numărul doritorilor din aceeași
                                    grădiniță și de tipul de abonament ales, care variază în funcție de durata acestuia
                                    raportat la numărul de luni. Părinții din cadrul clubului se angajează să participe
                                    la
                                    schimbul de cărți și la activitățile educative organizate prin platformă.
                                </li>
                                <li><strong>Părinți</strong> - se referă la persoanele care au copii înscriși în
                                    grădinițele participante și care utilizează platforma Raise a Reader pentru a
                                    achiziționa seturi de cărți și materiale educative, precum și pentru a participa la
                                    activitățile cluburilor și la schimburile de cărți. Termenul include atât
                                    părinții biologici, cât și tutorii legali sau alți îngrijitori responsabili de
                                    educația și dezvoltarea copiilor implicați în proiect.
                                </li>
                            </ul>
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>3. SCOPUL PLATFORMEI RAISE A READER</strong></h5>
                        <p>Platforma Raise A Reader este dedicată sprijinirii părinților în dezvoltarea obiceiurilor
                            de lectură pentru copiii preșcolari. Scopul nostru este de a încuraja lectura prin
                            oferirea unor resurse personalizate și structurate, adaptate vârstei și nevoilor de
                            dezvoltare ale copiilor, precum și prin crearea unor comunități de lectură active în
                            cadrul grădinițelor participante. </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>4. DESCRIEREA SERVICIILOR PUSE LA DISPOZIȚIE PRIN PLATFORMA RAISE A READER</strong>
                        </h5>
                        <p>În îndeplinirea scopului pentru care a fost creată, Platforma Raise A Reader se străduiește
                            să pună la dispoziția Utilizatorilor săi următoarele tipuri de servicii:
                            <ul>
                                <li><strong>Selecția de cărți</strong>: Comercializăm o varietate de cărți de înaltă
                                    calitate, atent selectate pentru a se potrivi diferitelor grupe de vârstă și
                                    niveluri de dezvoltare ale copiilor.
                                </li>
                                <li><strong>Planuri de lectură structurate</strong>: Planuri de lectură gândite pentru
                                    a facilita progresul în înțelegerea și plăcerea lecturii, adaptate vârstei
                                    copilului.
                                </li>
                                <li><strong>Resurse educative suplimentare</strong>: Ghiduri de discuții, întrebări
                                    interactive și sugestii de activități pentru a sprijini implicarea activă a
                                    părinților în procesul de lectură.
                                </li>
                                <li><strong>Crearea cluburilor pentru lectură în familie</strong>: Organizarea de cluburi
                                    în grădinițele participante, care implică grupuri de 4 până la 12 părinți, în
                                    funcție de numărul doritorilor și de tipul de abonament ales.
                                </li>
                                <li><strong>Coordonarea schimburilor de cărți</strong>: Platforma facilitează schimbul
                                    de cărți între părinți la intervale regulate, asigurând accesul continuu la lecturi
                                    noi pentru copii.
                                </li>
                            </ul>
                        </p>
                        <p>
                            <strong>Selecția Cărților și Planurile de Lectură</strong>
                            <p>
                                Selecția de cărți și planurile de lectură sunt alese cu grijă pentru a fi captivante și
                                potrivite vârstei copiilor. Deși ne străduim să oferim cărți și planuri care să răspundă
                                intereselor fiecărui copil, nu putem garanta că fiecare material va corespunde exact
                                nevoilor de dezvoltare ale fiecărui copil în parte. Cu toate acestea, sperăm să
                                descoperiți bucuria lecturii împreună cu copilul dumneavoastră prin materialele noastre.
                            </p>
                        </p>
                        <p>
                            <strong>Implementarea Microlibrăriei</strong>
                            <p>
                                Pentru a facilita schimburile de cărți, Platforma va oferi suport pentru înființarea
                                unei
                                microlibrării în cadrul grădinițelor participante. Grădinițele vor colabora cu părinții
                                pentru a organiza schimbul de cărți la date preselectate, asigurând astfel accesul la o
                                gamă variată de materiale educative.
                            </p>
                        </p>
                        <p>
                            <strong>Organizarea și Distribuirea Seturilor de Cărți în cadrul Cluburilor pentru lectură în familie</strong>
                            <p>
                                În cadrul cluburilor organizate la nivelul grădiniței, fiecare părinte
                                participant va primi un set inițial distinct de cărți, în funcție de tipul de abonament
                                ales (abonament pentru 4, până la 12 membri). Seturile inițiale de cărți sunt și vor rămâne proprietatea grădiniței. Fiecare set de cărți este unic,
                                astfel
                                încât fiecare copil din club să beneficieze de o varietate de materiale educative și de
                                lectură.
                            </p>
                            <p>
                                Pe parcursul desfășurării activității clubului pentru lectură în familie, în funcție de pachetul ales,
                                platforma Raise A Reader, împreună cu grădinița, va facilita schimburile de cărți între
                                membrii clubului de lectură. Acest sistem de schimburi permite fiecărui copil să aibă
                                acces, pe rând, la toate seturile de cărți achiziționate în cadrul respectivului club.
                            </p>
                            <p>
                                La finalul perioadei de abonament asociate clubului pentru lectură în familie, grădinița va
                                reprimi
                                în posesie seturile de cărți. Aceasta
                                asigură că, la încheierea activităților din cadrul clubului pentru lectură în familie, grădinița
                                își recuperează seturile inițiale complete de cărți.
                            </p>
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>5. IMPLICAREA PĂRINȚILOR ȘI SCHIMBURILE DE CĂRȚI</strong></h5>
                        <p>
                            Fiecare părinte participant va primi un set de cărți la începutul perioadei din abonamentul
                            ales pentru care s-a optat în cadrul clubului pentru lectură în familie din care părintele face parte.
                        </p>
                        <p>
                            Ulterior, în funcție de frecvența aplicabilă planului de abonament pentru care s-a optat
                            în cadrul clubului, fiecare părinte va fi notificat în legătură cu data la care
                            și persoanele cu care vor realiza următoarele schimburi de cărți. Este important de
                            menționat că responsabilitatea pentru respectarea programului de schimburi și pentru
                            păstrarea integrității cărților revine părinților. <strong>Platforma Raise A Reader se
                            bazează
                            pe angajamentul părinților de a-și îndeplini această sarcină, contribuind astfel la
                            obiectivul comun al proiectului.</strong>
                        </p>
                        <p>
                            Schimbul de cărți între părinți este esențial pentru a asigura accesul la o gamă variată
                            de materiale de lectură și educative de înaltă calitate pentru cât mai mulți copii. Prin
                            respectarea acestui proces, părinții susțin dezvoltarea continuă a copiilor din grup,
                            contribuind la formarea unei comunități de cititori entuziaști. Ne bazăm pe colaborarea
                            părinților pentru a îndeplini scopul platformei, acela de a spori eficiența și calitatea
                            accesului la resurse educative valoroase pentru fiecare copil.
                        </p>
                        <p>
                            Platforma Raise A Reader nu își poate asuma responsabilitatea în situațiile în care unul
                            sau mai mulți părinți din cadrul clubului pentru lectură în familie nu răspund la notificările privind
                            realizarea schimbului de cărți sau nu finalizează acest proces conform programului
                            stabilit. Deși facem toate eforturile pentru a asigura o experiență fluidă și eficientă
                            pentru toți participanții, succesul schimburilor depinde în mod direct de implicarea și
                            cooperarea fiecărui părinte. Încurajăm o comunicare deschisă între părinți pentru a
                            rezolva orice întârziere sau problemă în cadrul schimburilor, astfel încât niciun membru
                            al clubului să nu fie afectat negativ.
                        </p>
                        <p>
                            Totodată, platforma Raise A Reader nu poate fi responsabilă pentru eventualele deteriorări
                            ale cărților din cadrul clubului pentru lectură în familie, cauzate de utilizarea necorespunzătoare sau
                            de neglijența membrilor. Părinții sunt direct responsabili pentru păstrarea în bune
                            condiții a cărților primite, astfel încât toți membrii să poată beneficia de materiale de
                            calitate în timpul schimburilor. În cazul unor incidente, recomandăm părinților să se
                            asigure că integritatea cărților este menținută și să înlocuiască cărțile deteriorate dacă
                            este necesar.
                        </p>
                        <p>
                            Raise A Reader se angajează să sprijine activ părinții și să ofere asistență acolo unde
                            este posibil, dar responsabilitatea pentru realizarea schimburilor și menținerea calității
                            cărților rămâne exclusiv în sarcina părinților. Ne dorim ca fiecare membru să contribuie
                            pozitiv la atingerea scopului comun al platformei: facilitarea accesului la materiale
                            educative de înaltă calitate și încurajarea lecturii pentru toți copiii din grup.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>6. ÎNREGISTRAREA ȘI CREAREA CONTULUI ÎN PLATFORMĂ</strong></h5>
                        <p>
                            <strong>Procedura de înregistrare și Cerințele pentru Conturi</strong>
                        </p>
                        <p>
                            Pentru a accesa anumite funcționalități ale platformei Raise A Reader, inclusiv
                            achiziționarea
                            de cărți sau seturi de cărți și participarea la cluburile pentru lectură în familie, este necesară crearea
                            unui cont de utilizator.
                        </p>
                        <p>
                            În procesul de înregistrare, utilizatorul va furniza informații personale corecte și
                            complete,
                            care pot include: numele complet, adresa de e-mail, numărul de telefon și adresa de
                            domiciliu.
                            Prin crearea contului, garantați că toate datele furnizate sunt reale, exacte și complete.
                            Ne
                            rezervăm dreptul, dar nu și obligația, de a verifica autenticitatea datelor furnizate.
                        </p>
                        <p>
                            Pentru a utiliza platforma, trebuie să aveți cel puțin 18 ani. Prin înregistrarea unui cont,
                            confirmați că îndepliniți această cerință și sunteți de acord să folosiți platforma exclusiv
                            în scopurile prevăzute de aceasta. Utilizarea neautorizată a platformei, cum ar fi
                            exploatarea
                            datelor sau alte activități nepermise, este strict interzisă.
                        </p>
                        <p>
                            <strong>Obligațiile Utilizatorilor privind Informațiile Furnizate la Înregistrare</strong>
                        </p>
                        <p>
                            Utilizatorul este responsabil pentru asigurarea corectitudinii și actualizării datelor
                            furnizate
                            în timpul procesului de înregistrare. De asemenea, utilizatorul este unicul responsabil
                            pentru
                            menținerea confidențialității datelor de conectare la cont (numele de utilizator și parola).
                        </p>
                        <p>
                            În cazul în care suspectați utilizarea neautorizată a contului dvs. sau constatați orice
                            nereguli legate de securitatea contului, trebuie să ne notificați imediat la adresa de
                            e-mail
                            <a href="mailto:contact@raiseareader.ro">contact@raiseareader.ro</a>. Platforma Raise A
                            Reader
                            nu își asumă responsabilitatea pentru eventualele daune cauzate de utilizarea neautorizată a
                            contului dacă nu a fost notificată în timp util.
                        </p>


                        <p>
                            <strong>Securitatea Contului</strong>
                        </p>
                        <p>
                            Este responsabilitatea utilizatorului să protejeze datele de acces la cont și să asigure
                            securitatea acestuia. Platforma Raise A Reader nu va fi responsabilă pentru pierderile sau
                            daunele suferite ca urmare a accesului neautorizat la contul dvs., dacă nu ați luat măsurile
                            necesare pentru protecția datelor de autentificare. Notificați-ne imediat în cazul
                            utilizării
                            neautorizate a contului dvs. pentru a preveni accesul neautorizat sau alte activități
                            nepermise.
                        </p>

                        <p>
                            <strong>Anularea sau Refuzul Înregistrării</strong>
                        </p>
                        <p>
                            Platforma își rezervă dreptul de a anula sau refuza înregistrarea unui cont în situațiile
                            în care:
                            <ul>
                                <li>
                                    Datele furnizate de utilizator sunt incomplete, incorecte sau false;
                                </li>
                                <li>
                                    Nu au fost îndeplinite cerințele minime pentru înregistrare, cum ar fi vârsta de 18
                                    ani;
                                </li>
                                <li>
                                    Există suspiciuni de utilizare neautorizată sau abuzivă a platformei;
                                </li>
                                <li>
                                    În cazul în care înregistrarea este anulată, utilizatorul va fi notificat prin
                                    e-mail,
                                    fără obligații suplimentare din partea platformei.
                                </li>
                            </ul>
                        </p>

                        <p>
                            <strong>
                                Suspendarea și Încetarea Conturilor și a Drepturilor de utilizare a Platformei. Condiții
                                și Ipoteze.
                            </strong>
                        </p>
                        <p>
                            Dreptul de a utiliza platforma Raise A Reader va înceta automat la finalul perioadei de
                            valabilitate a abonamentului pentru care ați efectuat plata, în cazul în care nu veți opta
                            pentru reînnoirea acestuia.
                        </p>
                        <p>
                            Ne rezervăm dreptul de a suspenda imediat accesul dumneavoastră la platforma Raise A Reader
                            în
                            cazul în care Deținătorul Platformei constată că platforma este utilizată de dvs. în mod
                            abuziv sau modificată neautorizat în orice fel și/sau dacă încălcați oricare dintre acești
                            Termeni și Condiții.
                        </p>
                        <p>
                            De asemenea, accesul la platforma Raise A Reader poate fi încheiat și furnizarea serviciilor
                            poate înceta printr-o notificare simplă transmisă la adresa dumneavoastră de e-mail, cu
                            efect
                            imediat, dar, dacă este posibil, nu înainte de expirarea perioadei pentru care ați achitat
                            abonamentul, fără alte formalități juridice. Acest lucru se poate întâmpla în următoarele
                            situații:
                            <ul>
                                <li>
                                    Este necesar din motive legale sau ca urmare a unei decizii a unei autorități
                                    competente,
                                    ori Deținătorul Platformei încetează furnizarea accesului la Raise a Reader din
                                    orice
                                    motiv;
                                    sau
                                </li>
                                <li>
                                    Deținătorul Platformei decide că furnizarea serviciilor prin intermediul platformei
                                    Raise a
                                    Reader nu mai este fezabilă din punct de vedere comercial.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Totodată, Contul dvs. și dreptul de utilizare al Platformei vor înceta în cazul în care vă
                            exprimați în mod expres această opțiune prin comunicarea unui email la adresa
                        </p>

                        <p>
                            <strong>
                                Procedura de reactivare sau contestare a suspendării.
                            </strong>
                        </p>
                        <p>
                            Dacă accesul la platforma Raise a Reader a fost suspendat, puteți solicita reactivarea
                            contului sau contesta decizia prin trimiterea unui e-mail la
                            <a href="mailto:contact@raiseareader.ro">contact@raiseareader.ro</a> în termen de 7 zile de
                            la notificare. Vom analiza cererea în termen de 10 zile lucrătoare și vă vom informa cu
                            privire
                            la decizia luată. Decizia noastră va fi considerată finală.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>7. DREPTURILE ȘI OBLIGAȚIILE PĂRȚILOR</strong></h5>
                        <p>
                            <strong>Drepturile Utilizatorilor</strong>
                        </p>
                        <p>
                            Ca utilizator al platformei Raise A Reader, aveți următoarele drepturi:
                            <ul>
                                <li>
                                    Să accesați și să utilizați platforma și toate serviciile oferite prin intermediul
                                    acesteia în conformitate cu Termenii și Condițiile stabilite.
                                </li>
                                <li>
                                    Să beneficiați de resursele educative, seturile de cărți, ghidurile de lectură și
                                    toate
                                    celelalte servicii puse la dispoziție, pe baza abonamentului pentru care ați optat.
                                </li>
                                <li>
                                    Să participați la activitățile cluburilor pentru lectură în familie și la schimburile de cărți
                                    organizate între membrii platformei.
                                </li>
                                <li>
                                    Să vă exprimați sugestiile sau reclamațiile cu privire la serviciile platformei prin
                                    canalele de comunicare indicate.
                                </li>
                            </ul>
                        </p>
                        <p>
                            <strong>Obligațiile Utilizatorilor</strong>
                        </p>
                        <p>
                            În calitate de utilizator al platformei Raise A Reader, aveți următoarele obligații:
                            <ul>
                                <li>
                                    Să utilizați platforma în mod responsabil, în conformitate cu scopul său educativ și
                                    cu
                                    respectarea legislației aplicabile.
                                </li>
                                <li>
                                    Să respectați confidențialitatea datelor celorlalți utilizatori, inclusiv a
                                    părinților
                                    din cadrul cluburilor pentru lectură în familie, și să nu distribuiți informații personale ale
                                    acestora
                                    fără consimțământul explicit.
                                </li>
                                <li>
                                    Să vă asigurați că păstrați integritatea și calitatea cărților primite în cadrul
                                    schimburilor și să respectați programul de schimburi stabilit.
                                </li>
                                <li>
                                    Să nu utilizați platforma în scopuri neautorizate, cum ar fi exploatarea datelor sau
                                    utilizarea abuzivă a resurselor platformei.
                                </li>
                                <li>
                                    Să nu încercați să accesați conturile altor utilizatori sau să utilizați metode
                                    neautorizate de acces la platformă.
                                </li>
                                <li>
                                    Să furnizați informații exacte și actualizate la crearea contului și să păstrați
                                    confidențialitatea datelor de autentificare (numele de utilizator și parola).
                                </li>
                                <li>
                                    Să respectați legile și normele etice pe perioada utilizării platformei. Toți
                                    utilizatorii platformei sunt obligați să respecte legislația în vigoare și normele
                                    de
                                    conduită etică atunci când interacționează cu ceilalți membri ai platformei sau
                                    utilizează
                                    serviciile oferite. Comportamentul neadecvat, hărțuirea, abuzul de orice natură sau
                                    orice
                                    altă activitate ilegală sau necorespunzătoare vor duce la suspendarea sau anularea
                                    contului, fără notificare prealabilă.
                                </li>
                            </ul>
                        </p>
                        <p>
                            <strong>Cesionarea Drepturilor și Obligațiilor</strong>
                        </p>
                        <p>
                            Utilizatorii platformei Raise A Reader nu au dreptul de a cesiona sau transfera acești
                            Termeni și Condiții sau drepturile și licențele asociate contului lor către terțe părți.
                            Contul creat pe platformă este personal și netransferabil. Sunteți de acord că toate
                            drepturile aferente contului dumneavoastră sunt netransferabile și nu pot fi utilizate de
                            alte persoane.
                        </p>
                        <p>
                            Deținătorul platformei își rezervă dreptul de a cesiona acești Termeni, inclusiv
                            drepturile și licențele acordate, către orice persoană fizică sau juridică, fără
                            restricții. Nicio prevedere din acești Termeni nu conferă drepturi sau beneficii terțelor
                            părți, cu excepția cazului în care este prevăzut altfel în mod explicit.
                        </p>
                    </div>


                    <div className="fauna-one-regular align-left">
                        <h5><strong>8. TAXE ȘI PLĂȚI</strong></h5>
                        <p>
                            Participarea la programul <strong>Raise A Reader</strong> poate implica taxe și abonamente
                            care
                            vor fi comunicate în mod clar înainte de înscriere. Plățile sunt datorate conform planului
                            de
                            plată stabilit în momentul înregistrării și achiziționării abonamentului. Prețurile
                            abonamentelor deja achitate nu vor putea fi modificate pe durata valabilității
                            abonamentului,
                            însă ne rezervăm dreptul de a modifica prețurile abonamentelor viitoare sau necreate încă,
                            în
                            orice moment.
                        </p>
                        <p>
                            <strong>Modalități de plată</strong>
                        </p>
                        <p>
                            Plata pentru serviciile platformei se poate efectua exclusiv online, utilizând un card de
                            debit sau credit. După finalizarea selecției abonamentului și plasarea comenzii, veți fi
                            redirecționat către pagina procesatorului de plăți cu care colaborăm pentru a introduce
                            detaliile cardului. În cazul în care tranzacția nu este confirmată, este nevoie să reluați
                            procesul de plată.
                        </p>


                        <p>
                            <strong>Procesarea plăților</strong>
                        </p>
                        <p>
                            Prin plasarea unei plăți pe platforma Raise A Reader, autorizați procesatorul de plăți să
                            retragă suma aferentă abonamentului de pe cardul de debit sau credit. Plățile sunt procesate
                            printr-un furnizor de servicii de plată sigur, în conformitate cu standardele industriei de
                            securitate a datelor. Nu se percep taxe suplimentare pentru procesarea plății.
                        </p>
                        <p>
                            După confirmarea plății, veți primi un mesaj de mulțumire pe ecran, iar toate detaliile
                            relevante ale tranzacției vă vor fi transmise și prin e-mail. Plata efectuată va apărea pe
                            extrasul de cont sub denumirea Raise A Reader sau INFINITETECH SOLUTIONS SRL, în funcție de
                            procesatorul de plăți utilizat.
                        </p>
                        <p>
                            <strong>Securitatea datelor de plată</strong>
                        </p>
                        <p>
                            Securitatea informațiilor dvs. de plată este o prioritate pentru noi. Utilizăm tehnologii
                            avansate de criptare pentru a proteja datele cardului în timpul tranzacțiilor. Toate plățile
                            efectuate pe platformă sunt procesate prin intermediul furnizorilor de servicii de plată
                            siguri, care respectă standardele de securitate a datelor din industrie. Prin utilizarea
                            platformei Raise A Reader, sunteți de acord ca datele dvs. de plată să fie tratate cu cel
                            mai înalt nivel de confidențialitate și securitate.
                        </p>
                        <p>
                            <strong>Obligații privind metodele de plată</strong>
                        </p>
                        <p>
                            Prin efectuarea unei plăți, sunteți de acord să utilizați metode de plată valide și
                            autorizate. În cazul în care o plată nu poate fi procesată din motive legate de cardul
                            dvs., veți fi notificat și vi se va solicita să actualizați informațiile de plată.
                            Dacă problema nu este rezolvată în timp util, platforma își rezervă dreptul de a anula
                            abonamentul.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>9. POLITICA DE RETURNARE A PRODUSELOR</strong></h5>
                        <p>
                            Cărțile achiziționate prin platforma Raise A Reader pot fi returnate în conformitate cu
                            legislația națională privind protecția consumatorilor, respectiv O.U.G. nr. 34/2014 privind
                            drepturile consumatorilor în cadrul contractelor încheiate cu profesioniștii, precum și
                            pentru
                            modificarea și completarea unor acte normative (denumită în continuare O.U.G. 34/2014).
                        </p>
                        {/*<p>*/}
                        {/*    În cazul seturilor de cărți achiziționate în funcționarea cluburilor pentru lectură în familie, se*/}
                        {/*    consideră*/}
                        {/*    că sunt achiziționate și, prin urmare, pot fi returnate doar seturile inițiale de cărți*/}
                        {/*    primite de fiecare Utilizator la momentul înscrierii în clubul pentru lectură în familie. Aceste seturi de*/}
                        {/*    cărți pot fi returnate conform termenelor și condițiilor prevăzute de O.U.G. 34/2014.*/}
                        {/*</p>*/}
                        <p>
                            În cazul abonamentelor pentru funcționarea cluburilor pentru lectură în familie, aveți la
                            dispoziție 14 zile calendaristice de la data primirii setului inițial de cărți pentru
                            a vă exercita dreptul de retragere din contract, fără a justifica decizia și fără a suporta
                            penalități. Totuși, vă rugăm să rețineți că, dacă decideți să vă retrageți din contract,
                            veți pierde dreptul de a participa la schimbul lunar de cărți
                            organizat de clubul pentru lectură în familie din care faceți parte.
                        </p>
                        <p>
                            Pentru a solicita returnarea, trebuie să ne notificați în scris fie prin email la adresa de
                            e-mail <a href="mailto:contact@raiseareader.ro">contact@raiseareader.ro</a>, fie pe cale
                            poștală la adresa INFINITETECH SOLUTIONS SRL, cu
                            sediul în Chinteni, Str. Decebal, nr. 4, Județ Cluj, telefon +40752975382. În notificare, vă
                            rugăm să exprimați clar intenția de a vă retrage din contract. Odată ce primim această
                            notificare, vă vom trimite o confirmare de primire a cererii pe un suport durabil (de
                            exemplu,
                            prin e-mail sau prin sms). Este suficient să transmiteți notificarea înainte de expirarea
                            celor 14 zile pentru a respecta termenul legal.
                        </p>
                        {/*<p>*/}
                        {/*    Dacă optați pentru retragerea din contract, vom rambursa suma plătită pentru setul inițial*/}
                        {/*    de cărți, inclusiv taxele de livrare standard, în termen de maximum 14 zile de la data*/}
                        {/*    primirii notificării dumneavoastră. Putem amâna rambursarea până la primirea produselor*/}
                        {/*    returnate.*/}
                        {/*</p>*/}
                        {/*<p>*/}
                        {/*    Dacă vă retrageți, vom rambursa suma pe care am primit-o de la dumneavoastră cu titlu de*/}
                        {/*    plată pentru setul inițial de cărți inițial din abonament, inclusiv costurile livrării, cu*/}
                        {/*    excepția costurilor suplimentare determinate de faptul că ați ales altă modalitate de*/}
                        {/*    livrare decât cel mai ieftin tip de livrare standard oferit de noi, fără întârzieri*/}
                        {/*    nejustificate. Vom efectua această rambursare folosind aceeași modalitate de plată că şi*/}
                        {/*    cea folosită pentru tranzacția inițială, cu excepția cazului în care v-ați exprimat*/}
                        {/*    acordul expres pentru o altă modalitate de rambursare a comenzilor achitate prin ramburs.*/}
                        {/*    În acest caz, suma aferentă va fi virată într-un cont IBAN, neexistând posibilitatea*/}
                        {/*    rambursării cash. În orice caz, nu vi se vor percepe comisioane ca urmare a unei astfel de*/}
                        {/*    rambursări.*/}
                        {/*</p>*/}
                        <p>
                            Costurile directe de returnare vor fi suportate de către client.
                        </p>
                        <p>
                            Returnarea cărților poate fi efectuată prin curier sau prin intermediul grădiniței
                            partenere, pe baza facturii fiscale, în termen de cel mult 14 zile de la primirea
                            pachetului inițial. Termenul este considerat respectat dacă produsele sunt trimise înainte
                            de expirarea perioadei de 14 zile. În cazul în care alegeți returul online, un agent de
                            curierat va ridica produsele de la adresa indicată. Rambursarea se va efectua după ce
                            coletul a fost recepționat de către platforma noastră.
                        </p>
                        <p>
                            Pentru comenzile achitate cu vouchere, rambursarea se va face sub formă de voucher nou,
                            fără posibilitatea returnării în numerar sau prin alte metode de plată.
                        </p>
                        <p>
                            Produsele returnate trebuie să fie în starea inițială, fără semne de utilizare sau
                            deteriorări, cu toate accesoriile, cu etichetele intacte și alături de documentele care
                            le-au însoțit. Ne rezervăm dreptul de a refuza returnarea produselor care prezintă
                            modificări vizibile, cum ar fi zgârieturi, lovituri sau alte defecțiuni cauzate de
                            utilizare necorespunzătoare. Clientul este responsabil pentru orice scădere a valorii
                            produselor dacă acestea au fost manipulate în alt mod decât cel necesar pentru verificarea
                            calității și funcționalității acestora.
                        </p>
                        <p>
                            Excepțiile de la dreptul de retragere includ produse sigilate, cum ar fi înregistrările
                            audio sau video, care au fost desigilate după livrare, precum și conținutul digital
                            furnizat online, care nu este livrat pe suport fizic, atunci când prestarea acestuia a
                            început cu acordul explicit al consumatorului.
                        </p>
                        <p>
                            Astfel, în ceea ce privește abonamentul și accesul la platforma Raise A Reader, vă aducem
                            la cunoștință că, odată activat accesul la conținutul digital al platformei, anularea
                            abonamentului și returnarea sumelor achitate nu mai sunt posibile. Această condiție este
                            reglementată conform prevederilor din art. 16 lit. m) din O.U.G. 34/2014, referitoare la
                            furnizarea de conținut digital. Prin urmare, taxa plătită pentru accesul la platformă nu
                            va putea fi restituită.
                        </p>
                    </div>


                    <div className="fauna-one-regular align-left">
                        <h5><strong>10. DREPTUL DE REZILIERE A ABONAMENTULUI DE ACCES LA PLATFORMĂ</strong></h5>
                        <p>
                            <strong>Dreptul platformei de a rezilia sau suspenda accesul</strong>
                        </p>
                        <p>
                            Ne rezervăm dreptul de a rezilia sau suspenda accesul dumneavoastră la platforma Raise a
                            Reader în orice moment, cu sau fără notificare prealabilă și cu sau fără un motiv
                            explicit. Această măsură poate fi luată în cazul în care se constată încălcarea Termenilor
                            și Condițiilor sau pentru orice alte motive, la discreția platformei.
                        </p>

                        <p>
                            <strong>Rezilierea abonamentului de către Utilizator</strong>
                        </p>
                        <p>
                            Puteți renunța la abonament fără alte costuri suplimentare, cu condiția ca solicitarea de
                            reziliere să fie făcută înainte de data de 10 a lunii curente. Dacă solicitarea este
                            trimisă după data de 10 a lunii, veți fi responsabil pentru plata abonamentului și pentru
                            luna următoare, pe parcursul căreia veți continua să beneficiați de serviciile oferite de
                            platformă.
                        </p>
                        <p>
                            <strong> Efectele rezilierii</strong>
                        </p>
                        <p>
                            În cazul rezilierii abonamentului, fie din inițiativa Deținătorului Platformei,
                            fie din inițiativa Utilizatorului, accesul la toate serviciile platformei va fi
                            întrerupt la finalul perioadei pentru care s-a plătit ultima taxă. Nu se vor
                            acorda rambursări pentru sumele deja achitate, cu excepția cazurilor prevăzute în
                            mod expres în acești Termeni și Condiții.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>11. PROPRIETATE INTELECTUALĂ</strong></h5>

                        <p>
                            <strong> Drepturile de Proprietate Intelectuală</strong>
                        </p>
                        <p>
                            Toate materialele furnizate pe platforma Raise A Reader, incluzând dar fără a se limita la
                            planuri de lectură, resurse educative suplimentare, design-ul platformei, grafică, logo-uri
                            și orice alt conținut disponibil, sunt protejate de legile privind proprietatea intelectuală
                            și drepturile de autor aplicabile. Aceste materiale sunt proprietatea exclusivă a
                            Deținătorului Platformei sau sunt utilizate pe baza licențelor acordate de titularii
                            drepturilor de autor.
                        </p>

                        <p>
                            <strong> Restricții de Utilizare</strong>
                        </p>
                        <p>
                            Utilizatorii nu au dreptul să reproducă, distribuie, modifice, transmită, afișeze public, să
                            creeze lucrări derivate din, sau să exploateze în orice mod conținutul platformei Raise A
                            Reader, fără acordul expres și scris al Deținătorului platformei. Acest conținut nu poate fi
                            utilizat în afara scopului pentru care este oferit.
                        </p>

                        <p>
                            <strong> Cărțile și Materialele Educaționale</strong>
                        </p>
                        <p>
                            Cărțile și materialele educative comercializate pe platformă sunt protejate de drepturi de
                            autor conform legislației aplicabile. Aceste materiale sunt puse la dispoziția
                            utilizatorilor exclusiv pentru uz personal, iar copierea, distribuirea sau utilizarea lor în
                            alte scopuri este interzisă.
                        </p>

                        <p>
                            <strong> Utilizarea Software-ului și a Conținutului Digital</strong>
                        </p>
                        <p>
                            Utilizatorii acceptă că software-ul și conținutul digital asociat serviciilor oferite de
                            platformă conțin informații confidențiale și aflate sub protecția legilor privind drepturile
                            de proprietate intelectuală. Utilizatorilor le este interzis să copieze, modifice,
                            distribuie, transmită, vândă sau să creeze lucrări derivate din software-ul sau conținutul
                            digital la care au acces prin intermediul platformei Raise A Reader.
                        </p>

                        <p>
                            <strong> Cesionarea Drepturilor de Proprietate Intelectuală</strong>
                        </p>
                        <p>
                            Niciun drept, titlu sau licență asupra materialelor și conținutului de pe platforma Raise A
                            Reader nu este transferat utilizatorilor prin simpla utilizare a platformei. Deținătorul
                            platformei își rezervă dreptul de a cesiona drepturile și licențele asociate acestei
                            platforme către orice terță parte, fără restricții. Utilizatorii nu au dreptul de a cesiona
                            sau transfera drepturile și licențele acordate prin acești termeni.
                        </p>

                        <p>
                            <strong> Protecția Conținutului Platformei</strong>
                        </p>
                        <p>
                            Orice încercare de a accesa, copia, distribui, modifica sau exploata materialele platformei
                            Raise A Reader în afara scopurilor prevăzute de platformă este strict interzisă.
                            Utilizatorii care încalcă aceste reguli pot avea accesul suspendat sau anulat, iar
                            Deținătorul Platformei își rezervă dreptul de a acționa legal pentru protejarea drepturilor
                            sale de proprietate intelectuală.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>12. LIMITARE A RĂSPUNDERII</strong></h5>
                        <p>
                            <strong>Excluderi și limitări ale responsabilității platformei</strong>
                        </p>
                        <p>
                            Platforma Raise a Reader și serviciile oferite prin intermediul acesteia sunt furnizate „așa
                            cum sunt”, fără nicio garanție de funcționare continuă, fără erori sau întreruperi.
                            Deținătorul platformei nu garantează că serviciile vor fi disponibile în orice moment sau că
                            vor îndeplini toate cerințele utilizatorilor.
                        </p>
                        <p> În limita maximă permisă de lege, platforma Raise a Reader nu va fi răspunzătoare pentru
                            niciun fel de daune indirecte, accidentale, consecvente, speciale sau punitive rezultate din
                            utilizarea sau imposibilitatea de utilizare a platformei sau a serviciilor sale, incluzând,
                            dar fără a se limita la pierderi de date, întreruperea activității sau orice altă pierdere
                            financiară.
                        </p>
                        <p> Deținătorul platformei nu își asumă responsabilitatea pentru neîndeplinirea sau executarea
                            necorespunzătoare a obligațiilor contractuale care se datorează unor evenimente aflate în
                            afara controlului său, precum acțiuni sau inacțiuni ale terților, probleme de rețea,
                            defecțiuni tehnice sau alte circumstanțe neprevăzute.
                        </p>
                        <p>
                            <strong>Exonerarea de răspundere pentru acțiuni ale terților</strong>
                        </p>
                        <p>
                            Platforma Raise A Reader nu își asumă răspunderea pentru acțiunile terților care
                            interacționează pe platformă, inclusiv, dar fără a se limita la schimburile de cărți între
                            părinți. Deși ne străduim să facilităm și să sprijinim organizarea acestor schimburi,
                            platforma nu este responsabilă pentru eventualele neînțelegeri, întârzieri, pierderi sau
                            deteriorări care pot apărea în cadrul acestui proces.
                        </p>
                        <p> Utilizatorii platformei sunt responsabili pentru respectarea regulilor stabilite în cadrul
                            schimburilor de cărți și pentru asigurarea integrității materialelor pe care le schimbă.
                            Orice litigii sau probleme care pot apărea între părinți în legătură cu schimburile de cărți
                            trebuie soluționate pe cale amiabilă între părțile implicate, fără implicarea directă a
                            platformei.
                        </p>
                        <p>
                            <strong>Forța Majoră și Cazul Fortuit</strong>
                        </p>
                        <p>
                            Deținătorul platformei Raise A Reader nu va fi considerat responsabil pentru neîndeplinirea
                            sau întârzierea îndeplinirii obligațiilor sale prevăzute în Termenii și Condițiile
                            platformei, dacă acestea sunt cauzate de forța majoră sau alte evenimente fortuite aflate în
                            afara controlului său. Aceste evenimente includ, dar nu se limitează la, calamități
                            naturale, conflicte armate, restricții guvernamentale, probleme tehnice neprevăzute sau alte
                            circumstanțe care împiedică funcționarea normală a platformei.
                            În cazul unui astfel de eveniment de forță majoră sau a unui caz fortuit, obligațiile
                            platformei vor fi suspendate pe durata evenimentului, fără ca utilizatorii să poată pretinde
                            despăgubiri sau penalități pentru această întârziere.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>13. POLITICA DE CONFIDENȚIALITATE ȘI PROTECȚIA DATELOR</strong></h5>
                        <p>
                            Confidențialitatea dumneavoastră este importantă pentru noi. Vă rugăm să consultați Politica
                            noastră de Confidențialitate pentru informații despre modul în care colectăm, utilizăm și
                            protejăm datele dumneavoastră personale.
                        </p>
                    </div>


                    <div className="fauna-one-regular align-left">
                        <h5><strong>14. MODIFICĂRI ALE TERMENILOR ȘI CONDIȚIILOR</strong></h5>
                        <p>
                            <strong>Dreptul Platformei de a modifica Termenii și Condițiile</strong>
                        </p>
                        <p>
                            Deținătorul Platformei Raise A Reader își rezervă dreptul de a modifica sau actualiza acești
                            Termeni și Condiții în orice moment, în funcție de evoluțiile legale, operaționale sau
                            comerciale. Aceste modificări pot include actualizări ale politicilor de utilizare, ale
                            serviciilor oferite sau ale cerințelor de securitate.
                        </p>
                        <p>
                            <strong>Modalitatea de informare a utilizatorilor cu privire la modificări</strong>
                        </p>
                        <p>
                            Orice modificare a Termenilor și Condițiilor va fi comunicată utilizatorilor prin publicarea
                            versiunii actualizate pe platforma Raise A Reader. În funcție de natura modificărilor,
                            utilizatorii pot fi notificați și prin e-mail sau alte mijloace electronice de comunicare,
                            utilizând datele de contact furnizate la înregistrare.
                        </p>
                        <p>
                            <strong>Acceptarea modificărilor</strong>
                        </p>
                        <p>
                            După ce modificările au fost publicate sau comunicate, utilizarea continuă a platformei de
                            către utilizatori va fi considerată ca acceptare implicită a noilor Termeni și Condiții.
                            Dacă nu sunteți de acord cu modificările aduse, vă recomandăm să încetați utilizarea
                            platformei Raise A Reader și să solicitați închiderea contului.
                        </p>
                        <p>
                            <strong>Perioada de intrare în vigoare a modificărilor</strong>
                        </p>
                        <p>
                            Noile Termeni și Condiții vor intra în vigoare în termen de 5 zile de la publicarea acestora
                            pe platformă, cu excepția cazurilor în care legea prevede altfel sau în situații de urgență
                            în care aplicarea imediată a modificărilor este necesară pentru protejarea securității
                            utilizatorilor sau a platformei. În timpul acestei perioade, utilizatorii au posibilitatea
                            să-și revizuiască abonamentele sau să solicite informații suplimentare.
                        </p>
                        <p>
                            <strong>Obligația utilizatorilor de a revizui Termenii și Condițiile</strong>
                        </p>
                        <p>
                            Utilizatorii sunt încurajați să revizuiască periodic Termenii și Condițiile pentru a fi la
                            curent cu orice modificare sau actualizare care poate influența utilizarea platformei.
                            Platforma Raise A Reader nu își asumă responsabilitatea pentru utilizarea platformei în
                            necunoștință de cauză a noilor condiții.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>15. NOTIFICĂRI ȘI COMUNICAREA ÎNTRE PĂRȚI</strong></h5>
                        <p>
                            Pentru orice notificare legată de acești Termeni și Condiții și/sau utilizarea Platformei
                            Raise a Reader, ne puteți contacta prin e-mail la <a
                            href="mailto:contact@raiseareader.ro">contact@raiseareader.ro</a>.
                        </p>
                        <p>Dacă aveți o reclamație referitoare la acești Termeni și Condiții și/sau accesul sau
                            utilizarea Platformei Raise a Reader vă rugăm să ne anunțați imediat. Reclamația trebuie
                            trimisă prin e-mail, având subiectul "Reclamație", de la adresa de e-mail pe care ați
                            înregistrat-o în contul dvs., către <a
                                href="mailto:contact@raiseareader.ro">contact@raiseareader.ro</a> .
                        </p>
                        <p>Este posibil să solicităm informații suplimentare pentru a înțelege mai bine reclamația dvs.
                            și pentru a putea oferi un răspuns adecvat. Comunicările suplimentare, fie întrebări din
                            partea noastră, fie răspunsuri la reclamația dvs., vor fi transmise la adresa de e-mail
                            înregistrată în contul dvs.
                        </p>
                        <p>Ne străduim să îmbunătățim continuu serviciile noastre, așa că apreciem orice sugestie sau
                            feedback. Dacă aveți întrebări sau observații, vă rugăm să ne contactați la
                            <a href="mailto:contact@raiseareader.ro">contact@raiseareader.ro</a>, iar echipa noastră vă
                            va răspunde cât mai prompt posibil.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>16. LEGISLAȚIA APLICABILĂ ȘI JURISDICȚIA COMPETENTĂ</strong></h5>
                        <p>
                            Acești Termeni și Condiții și relația dintre dvs. și noi creată de aceștia vor fi guvernate
                            și interpretate în conformitate cu legile României. Orice litigiu în legătură cu acești
                            Termeni și Condiții Generale va fi supus jurisdicției
                            exclusive a instanțelor competente de la sediul INFINITECH SOLUTIONS SRL.
                        </p>
                    </div>

                    <div className="fauna-one-regular align-left">
                        <h5><strong>17. DISPOZIȚII FINALE</strong></h5>
                        <p>
                            În cazul în care o dispoziție din acești Termeni și Condiții este considerată în mod
                            judiciar ca fiind invalidă, ilegală sau neaplicabilă, validitatea, legalitatea și
                            aplicabilitatea celorlalte dispoziții ale acestuia nu vor fi în niciun fel afectate sau
                            împiedicate.
                        </p>
                        <p>Pentru a utiliza Platforma Raise A Reader trebuie să citiți cu atenție și să acceptați acești
                            Termeni și Condițiile conținute de prezentul Document. Prin utilizarea/accesarea platformei
                            și serviciilor noastre înregistrarea datelor dumneavoastră, recunoașteți că ați citit,
                            înțeles, sunteți de acord și acceptați implicit să fiți legați de acești Termeni și
                            Condiții, care au valoarea unui contract tacit între Utilizator și Deținătorul Platformei.
                            În consecință, acești Termeni și Condiții constituie acordul dintre părți și reglementează
                            accesul Utilizatorului la serviciile furnizate prin intermediul Platformei.
                        </p>
                        <p> Dacă nu sunteți de acord cu acești Termeni și Condiții sau nu doriți să respectați regulile
                            stipulate în cuprinsul acestora, vă rugăm să nu utilizați serviciile oferite prin
                            intermediul acestei Platforme.
                        </p>
                        <p> În situația în care, după lansarea Platformei, vor fi adăugate servicii noi disponibile pe
                            Platformă, acestea vor fi guvernate de aceleași reguli și condiții stabilite în prezentul
                            document, cu excepția cazului în care vor fi specificate condiții distincte pentru acele
                            servicii suplimentare.
                        </p>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Terms;
