
export const sanitizeAndTruncate = (input: string, maxLength: number) =>{
    if (!input) return "";

    const plainText = input
        .replace(/<[^>]*>/g, "") // Remove HTML tags
        .replace(/\s+/g, " ")    // Normalize whitespace
        .trim();

    if (plainText.length <= maxLength) return plainText;

    // Truncate at nearest word boundary if possible
    const truncated = plainText.slice(0, maxLength);
    const lastSpace = truncated.lastIndexOf(" ");
    return lastSpace > 0 ? truncated.slice(0, lastSpace) + "…" : truncated + "…";
}

export const sanitizeMetaTitle = (input: string) => {
    return sanitizeAndTruncate(input, 60);
}

export const sanitizeMetaDescription = (input: string) => {
    return sanitizeAndTruncate(input, 160);
}
