import * as React from 'react';
import {Button} from 'react-bootstrap'
import {IconButton} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {applyHere} from "../../services/userService";
import ANPC_solutionarea_alternativa from '../../assets/anpc/ANPC_solutionarea_alternativa.png';
import ANPC_solutionarea_online from '../../assets/anpc/ANPC_solutionarea_online.png';

export default function AppFooter() {
    return (
        <footer>
            <div className="App-footer parent">
                <div className="column-33"
                     style={{alignItems: 'center', justifyContent: "center", flexDirection: "row"}}>
                    <IconButton aria-label="fingerprint" sx={{"&:hover": {color: "#6C00A9"}}}
                                onClick={event => window.open('https://www.facebook.com/raiseareader.kids')}>
                        <FacebookIcon sx={{color: "var(--cefacem-dark-color)"}}/>
                    </IconButton>
                    <IconButton aria-label="fingerprint" sx={{"&:hover": {color: "#6C00A9"}}}
                                onClick={event => window.open('https://www.instagram.com/raiseareader.kids')}>
                        <InstagramIcon sx={{color: "var(--cefacem-dark-color)"}}/>
                    </IconButton>
                </div>
                <div className="column-33" style={{alignItems: 'center'}}>
                    <Typography>Copyright © 2025 RaiseAReader All rights reserved.</Typography>
                </div>
                <div className="column-33" style={{alignItems: 'self-end', flexDirection: "row", flexWrap: "wrap"}}>
                    <Link to={`/contact-us`} onClick={applyHere}>
                        <Button className="button-just-text2">
                            <Typography>Contact</Typography>
                        </Button>
                    </Link>
                    <Link to={`/terms`}>
                        <Button className="button-just-text2">
                            <Typography>Termeni și condiții</Typography>
                        </Button>
                    </Link>
                    <Link to={`/privacy`}>
                        <Button className="button-just-text2">
                            <Typography>Confidențialitate</Typography>
                        </Button>
                    </Link>
                </div>
            </div>

            <div className="App-footer parent">
                <div className="column-33">
                </div>
                <div className="column-33"  style={{justifyContent: "center", flexDirection: "row", flexWrap: "wrap"}}>
                    <a href="https://reclamatiisal.anpc.ro/" target="_blank">
                        <img src={ANPC_solutionarea_alternativa} width="250" height="65"
                             alt="Solutionarea alternativa a litigiilor"/>
                    </a>
                    <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=RO"
                       target="_blank">
                        <img src={ANPC_solutionarea_online} width="250" height="65"
                             alt="Solutionarea online a litigiilor"/>
                    </a>
                </div>

                <div className="column-33">
                </div>
            </div>
        </footer>
    );
}
