import React, {ChangeEvent, FormEvent, KeyboardEventHandler, useRef, useState} from 'react';
import '../App.css';
import Form from "react-bootstrap/Form";
import Typography from "@mui/material/Typography";
import {Link, useNavigate} from "react-router-dom";
import {getResetPasswordCode, register, setResetPassword} from "../services/userService";
import {RegisterRequest} from "../services/model/RegisterRequest";
import {Button, Col, Row} from "react-bootstrap";
import {ResetPasswordRequest} from "../services/model/ResetPasswordRequest";
import {Helmet} from "react-helmet-async";

function ForgotPassword() {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [serverError, setServerError] = useState(false);
    const [resetPasswordRequest, setResetPasswordRequest] = useState<ResetPasswordRequest>({
        email: '',
        resetCode: '',
        newPassword: ''
    });

    const handleChange = (e : ChangeEvent<HTMLInputElement>) => {
        setResetPasswordRequest({
            ...resetPasswordRequest,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (event: FormEvent) => {
        const form = event.currentTarget;
        const submitEvent = event.nativeEvent as SubmitEvent;

        event.preventDefault();
        event.stopPropagation();

        // @ts-ignore
        if (submitEvent && submitEvent.submitter && submitEvent.submitter.name === 'getCode') {
            getResetPasswordCode({ email: resetPasswordRequest.email }).then((response) => {
                setServerError(false);
            }).catch(error => {
                console.error('Reset password get code Error :', error);
                setServerError(true);
            });

            setResetPasswordRequest({
                ...resetPasswordRequest,
                resetCode: '',
                newPassword: ''
            });
            setValidated(false); // Reset validation state
        } else {
            // @ts-ignore
            if (form.checkValidity() === false) {
                setValidated(true);
            } else {
                setResetPassword(resetPasswordRequest).then((response) => {
                    setServerError(false);
                    navigate("/login");
                    window.location.reload();
                }).catch(error => {
                    console.error('Reset password Error :', error);
                    setServerError(true);
                });
            }
        }
    };

    function sendResetCode() {
        if (!resetPasswordRequest.email ) {
            console.log(resetPasswordRequest.email);
        }
        console.log("empty")
    }

    return (
      <>
          <Helmet>
              <title>Resetare parolă – Raise a Reader</title>
              <meta
                  name="description"
                  content="Resetează parola contului tău Raise a Reader."
              />
              <link rel="canonical" href="https://www.raiseareader.ro/reset-password" />
              <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          <div className="App">
              <div id="wave" className="parent top-section div-with-background">
                  <div className="small-container align-left-mobile-only">
                      <h1>
                          Mi-am uitat parola
                      </h1>
                      <h5>
                          <li>Introdu adresa de email asociata contului.</li>
                          <li>Apasa trimite cod pentru a primi codul de resetare pe email.</li>
                          <li>Introdu codul primit pe email și parola nouă și apasă resetează parola.</li>
                      </h5>
                  </div>
              </div>
              <div className="parent top-section div-with-background">
                  <div className="border align-left"
                       style={{marginTop: '10px', color: "black", fontSize: "initial", minWidth: "360px"}}>
                      {serverError ? <div className="from-error">Eroare: ceva nu a funcționat. Asigură-te că ai introdus email-ul si codul de resetare corecte.</div> : <></>}
                  <Form id="login-form" noValidate validated={validated} onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                          <Row className="g-2">
                              <Col md>
                                  <Form.Label>
                                      Email *
                                  </Form.Label>
                                  <Form.Control

                                      type="email"
                                      name="email"
                                      placeholder="Email"
                                      onChange={handleChange}
                                      required
                                  />
                                  <Form.Control.Feedback type="invalid">
                                      Te rugăm să introduci email-ul.
                                  </Form.Control.Feedback>
                              </Col>
                              <Col md style={{alignSelf: 'flex-end'}}>
                                  <Button className="button button-contact" type="submit" name="getCode">
                                      Trimite cod
                                  </Button>
                              </Col>
                          </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" style={{paddingTop: '5px'}}>
                          <Form.Label>
                              Codul de resetare *
                          </Form.Label>
                          <Form.Control
                              type="input"
                              required
                              name="resetCode"
                              placeholder="Cod de resetare"
                              onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                              Te rugăm să introduci un codul de resetare valid.
                          </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3"  style={{paddingTop: '5px'}}>
                          <Form.Label>
                              Parola nouă *
                          </Form.Label>
                          <Form.Control
                              type="password"
                              required
                              name="newPassword"
                              placeholder="Parola nouă"
                              onChange={handleChange}
                          />
                          <Form.Control.Feedback type="invalid">
                              Te rugăm să introduci un email valid.
                          </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" style={{textAlign: 'center'}}>
                          <Button type="submit" className="button button-contact" name="sendReset">
                              Resetează parola
                          </Button>
                          <Typography id="modal-modal-title" variant="h6" component="h2"
                                      className="fauna-one-regular header span-minor-text"
                                      style={{textAlign: 'center'}}>
                              Ai deja cont?<br/> <Link to="/login">Loghează-te aici</Link>
                          </Typography>
                      </Form.Group>

                  </Form>
                  </div>
              </div>
          </div>

          <div className="gradient-wave-bg"></div>
      </>
  );
}

export default ForgotPassword;
