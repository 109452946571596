import {useEffect, useState} from 'react'

interface ReadMoreProps {
    id: string
    text: string
    amountOfWords?: number
    isHtml?: boolean
}

export const ReadMore = ({ id, text, amountOfWords = 36, isHtml = false}: ReadMoreProps) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [htmlText, setHtmlText] = useState('')
    const splittedText = text.split(' ')
    const itCanOverflow = splittedText.length > amountOfWords
    const beginText = itCanOverflow
        ? splittedText.slice(0, amountOfWords - 1).join(' ')
        : text
    const endText = splittedText.slice(amountOfWords - 1).join(' ')

    useEffect(() => {
        isExpanded ?
            setHtmlText(beginText + endText)
        :
            setHtmlText(beginText + "...")
        }, [isExpanded])

    return (
        <p id={id}>

            {isHtml ?
                <>
                <span dangerouslySetInnerHTML={{__html: htmlText}} />
                </> :
                <>
                  {beginText}
                </>
            }

            {itCanOverflow && (
                <>
                    {!isExpanded && !isHtml && <span>... </span>}
                    {isHtml ?
                        <>
                        </> :
                        <>
                            <span className={`${!isExpanded && 'hidden'}`}
                                  aria-hidden={!isExpanded}>
                                  {endText}
                            </span>
                        </>
                    }

                    <span
                        className='text-violet-400 ml-2'
                        role="button"
                        tabIndex={0}
                        aria-expanded={isExpanded}
                        aria-controls={id}
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        <span className="low-highlight deep-purple">
                            {isExpanded ? ' Ascunde' : ' Citește mai mult'}
                        </span>
                    </span>

                </>
            )}
            </p>
                )
            }