import axios from 'axios'
import {apiUrl} from './environment'
import {
    getNoAuthId,
    getRefreshToken,
    getToken, removeRefreshToken, removeToken,
    setAuthenticated,
    setRefreshToken,
    setToken
} from "../../services/authStorageService";
import {useNavigate} from "react-router-dom";
import {getNoAuthUserId} from "../../services/userService";

export const http = axios.create({
  baseURL: apiUrl + "/api",
})

export const httpNoAuth = axios.create({
    baseURL: apiUrl + "/api",
})


export const auth = axios.create({
  baseURL: apiUrl + "/auth",
})

http.interceptors.request.use(
    async (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            config.headers.Authorization = null;
            config.headers["x-user-id"] = await getNoAuthId();
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
http.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If the error status is 401 and there is no originalRequest._retry flag,
      // it means the token has expired and we need to refresh it
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const refreshToken = getRefreshToken()
          const response = await auth.post('/refresh-token', { "refresh_token" : refreshToken });
          let token = response.data.access_token;
          let refreshTokenNew = response.data.refresh_token;

            setToken(token);
            setRefreshToken(refreshTokenNew);

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return http(originalRequest);
        } catch (error) {
          // Handle refresh token error or redirect to login
            removeToken();
            removeRefreshToken();
            setAuthenticated(false)
            originalRequest.headers.Authorization = null;
            window.location.href="/login";
        }
      }

      return Promise.reject(error);
    }
);