import React, {useCallback, useEffect, useState} from 'react';
import '../App.css';
import {getUserReadingPlan} from "../services/readingPlan";
import {IUserReadingPlan} from "../services/model/IUserReadingPlan";
import {Box, Grid} from "@mui/material";
import BookInPlan from "./BookInPlan";
import {ArrowBack, ArrowForward} from "@mui/icons-material";
import {Button} from "react-bootstrap";
import {Helmet} from "react-helmet-async";

function UserReadingPlan() {
    const [readingPlans, setReadingPlans] = useState<IUserReadingPlan[]>([]);
    const [selectedPlan, setSelectedPlan] = useState<number>(0);
    const [kidId, setKidId] = useState<string>();

    const getData = useCallback(async () => {
        try {
            const response = await getUserReadingPlan();
            setReadingPlans(response.data);
            // if (response.data.length > 0) {
            //     setSelectedPlan(0);
            //     setKidId(response.data[0].kidProfile?.id);
            // }

        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        getData();
    },[])

    return (
      <>
          <Helmet>
              <title>Planul meu de lectură – Raise a Reader</title>
              <meta
                  name="description"
                  content="Descoperă planul tău personalizat de lectură, creat în funcție de vârsta copilului și interesele voastre. Doar pentru utilizatorii cu abonament activ."
              />
              <link rel="canonical" href="https://www.raiseareader.ro/my-plan" />
              <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          <div className="App">
              <div id="wave" className="parent align-left top-section div-with-background">
                  <div className="small-container ">
                      <br/>
                      <h1>Planul meu - {readingPlans[selectedPlan]?.kidProfile?.name}</h1>
                      <h5>Aici poți să vezi cărtile din clubul tău, când are loc fiecare schimb și ce cărți vei primi în fiecare pachet.</h5>
                      <Box display="flex" justifyContent="space-between" flexWrap="wrap">

                          {readingPlans.length > 1 && readingPlans.map((plan, index) => (
                              <Button className={"button-just-text"} key={index} onClick={() => { setSelectedPlan(index); setKidId(plan.kidProfile?.id); }}>
                                  Planul pentru {plan.kidProfile?.name}
                              </Button>
                          ))}
                      </Box>
                  </div>
              </div>
              <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
              <div className="App-body fauna-one-regular">
                  <div className="fauna-one-regular">
                      {readingPlans[selectedPlan]?.readingGroups
                          .map((group, index) => {
                              return (

                                  <Box id="sized-container" style={{marginBottom: "10px"}} border={4} borderColor={group.color} borderRadius="15px">
                                         {/*<Box bgcolor={group.color} display="flex">*/}
                                         <Box display="flex" bgcolor={group.color} marginBottom="5px">
                                             <span className="span-bold-text" style={{color:"white", padding:"5px"}}>{new Date(group.startTimestamp).toLocaleDateString("ro", {day: "2-digit", month: "long"})} - {new Date(group.endTimestamp).toLocaleDateString("ro", {day: "2-digit",month: "long"})}</span>
                                         </Box>
                                         {/*<Grid container spacing={2} bgcolor={group.color}>*/}
                                         <Grid container spacing={2}>
                                             {group?.books
                                                 .map((book, index1) => {
                                                     return (
                                                         <BookInPlan book={book}
                                                                     group= {group}
                                                                     isReserve = {false}
                                                                     isAdditionalBook = {false}
                                                                     kidProfileId={kidId}
                                                                     backupPlanColor={readingPlans[selectedPlan]?.backupGroupColor}
                                                                     />
                                                     )}
                                             )}
                                             {group.additionalBook ?
                                                 <BookInPlan book={group.additionalBook}
                                                             group= {group}
                                                             isReserve = {false}
                                                             isAdditionalBook = {true}
                                                             backupPlanColor={readingPlans[selectedPlan]?.backupGroupColor}
                                                             kidProfileId={kidId}/>
                                                 :
                                                 <BookInPlan
                                                             group= {group}
                                                             isReserve = {true}
                                                             isAdditionalBook = {true}
                                                             backupPlanColor={readingPlans[selectedPlan]?.backupGroupColor}
                                                             kidProfileId={kidId}/>
                                             }
                                  </Grid>
                              </Box>
                              )}
                          )}
                  </div>
              </div>
          </div>

      </>
  )
      ;
}

export default UserReadingPlan;
