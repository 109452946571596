import {http} from "../core/services/http";
import {AddItemRequest, AddNoteRequest, AddVoucherRequest, UserBag} from "./model/IBagModel";

export const getNoAuthUserBag = () =>
    http.get<UserBag>('/commercial/noauth/user-bag')

export const addNoAuthToUserBag = (request: AddItemRequest) =>
    http.post<UserBag>('/commercial/noauth/user-bag/item', request)

export const deleteNoAuthItem = (id:string) =>
    http.delete<UserBag>(`/commercial/noauth/user-bag/item/${id}`)

export const setNoAuthVoucherOnBag = (request: AddVoucherRequest) =>
    http.post<UserBag>('/commercial/noauth/user-bag/voucher/add', request)

export const setNoAuthNoteOnBag = (request: AddNoteRequest) =>
    http.post<UserBag>('/commercial/noauth/user-bag/note', request)


export const removeNoAuthVoucherFromBag = () =>
    http.delete<UserBag>('/commercial/noauth/user-bag/voucher')
