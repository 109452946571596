import {http} from "../core/services/http";
import {AddItemRequest, AddNoteRequest, AddVoucherRequest, UserBag} from "./model/IBagModel";

export const getUserBag = () =>
    http.get<UserBag>('/commercial/user-bag')

export const addToUserBag = (request: AddItemRequest) =>
    http.post<UserBag>('/commercial/user-bag/item', request)

export const deleteItem = (id:string) =>
    http.delete<UserBag>(`/commercial/user-bag/item/${id}`)

export const setVoucherOnBag = (request: AddVoucherRequest) =>
    http.post<UserBag>('/commercial/user-bag/voucher/add', request)

export const setNoteOnBag = (request: AddNoteRequest) =>
    http.post<UserBag>('/commercial/user-bag/note', request)


export const removeVoucherFromBag = () =>
    http.delete<UserBag>('/commercial/user-bag/voucher')


var bagCount = 0
export const setBagCountOnEvent = async () => {
    await http.get<number>(`/commercial/user-bag/count-bag-items`).then((response) => {
        bagCount = response.data;
        localStorage.setItem("bagCount", bagCount.toString());
        const event = new CustomEvent('bagChange');
        window.dispatchEvent(event);
    });
    return bagCount;
}

export const getBagCountOnEvent = () => {
    return localStorage.getItem("bagCount");
}

export const migrateBag = (id: string) =>
    http.post(`/commercial/user-bag/migrate-no-auth/${id}`)