import React, {useCallback, useEffect, useState} from 'react';
import '../App.css';
import {getBookPackageDetails} from "../services/booksService";
import {useParams} from "react-router-dom";
import {BookPackageDetails} from "../services/model/Packages";
import PackageDetailsElement from "./PackageDetailsElement";


function PackageDetails() {
    const {slug} = useParams();

    const [pack, setPack] = useState<BookPackageDetails>();

    const getData = useCallback(async () => {
        try {
            const packResponse = await getBookPackageDetails(slug!);
            setPack(packResponse.data);
        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        getData();

    }, [])



    return (
      <>

          <div className="App">

              <div className="App-body fauna-one-regular">
                  <div className="fauna-one-regular terms" style={{width: "100%"}}>
                      {pack === undefined ?
                          <div>
                          </div>
                          :
                          <>
                            <PackageDetailsElement pack={pack}/>
                          </>
                      }
                  </div>
              </div>
          </div>

      </>
  );
}

export default PackageDetails;