import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import carteA from "../../assets/incepe/carte_a.png";
import NewsletterSignupForm from "./NewsletterSignupForm";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {applyHere} from "../../services/userService";

export default function ActionSection() {
    return (
        <>
            <div className="gradient-wave-bg flip" style={{transform: 'rotate(180deg)'}}></div>
            <div className="parent align-left top-section div-with-background"  style={{marginTop: '-5px'}}>
                <div id="incepe2" className="parent action-div">
                    <div className="column-33">
                        <div className="column-50-no-equal">
                            <div className="span-minor-text-without-padding white-and-bold-text">Vrei un club
                                RaiseAReader în grădinița ta?
                                <List>
                                    <ListItem disablePadding>
                                        <StarIcon/>
                                        <ListItemText style={{paddingLeft: "7px"}}
                                                      primary="Plătești cu până la 70% mai puțin decât dacă ai cumpăra cărțile individual!"/>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <StarIcon/>
                                        <ListItemText style={{paddingLeft: "7px"}}
                                                      primary="Te simți grozav pentru că ai prioritizat citirea pe pilot automat!"/>
                                    </ListItem>
                                </List>
                            </div>

                            <Link to="/contact-us">
                                <Button className="button button-contact button-just-text" onClick={applyHere}>
                                    Aplică aici
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className="column-33">
                        <div><img className="carteA" src={carteA}></img></div>
                    </div>

                    <div className="column-33">
                        <NewsletterSignupForm/>
                    </div>
                </div>
            </div>
            <div className="gradient-wave-bg" style={{marginTop: '-30px', transform: 'rotate(360deg)'}}></div>
        </>

    );
}
