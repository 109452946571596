import {getNoAuthUserId, verifyNoAuthUserId} from "./userService";
import {setBagCountOnEvent} from "./bagService";

export const getToken = () => {
    return localStorage.getItem('token');
}

export const removeToken = () => {
    return localStorage.removeItem('token');
}

export const setToken = (token : string) => {
    return localStorage.setItem('token', token);
}

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
}

export const removeRefreshToken = () => {
    return localStorage.removeItem('refreshToken');
}

export const setRefreshToken = (token : string) => {
    return localStorage.setItem('refreshToken', token);
}

export const isAuthenticated = () => {
    return localStorage.getItem('authenticated') !== undefined && localStorage.getItem('authenticated') === "true";
}

export const setAuthenticated = (authenticated : boolean) => {
    return localStorage.setItem('authenticated', authenticated ? 'true' : 'false');
}
async function setNewNoAuthToken() {
    console.log("Generating new Auth Token");
    var noAuth = await getNoAuthUserId();
    setNoAuthId(noAuth.data.id);
    setBagCountOnEvent();
    return noAuth.data.id;
}

export const getNoAuthIdNoUpdate = () => {
    var item = localStorage.getItem('noAuthToken');
    if (item === null) {
        console.log("No Auth Token");
        return setNewNoAuthToken();
    }
    return item;
}

let getNoAuthIdLock: Promise<string | null> | null = null;

export const getNoAuthId = async () => {
    if (getNoAuthIdLock) {
        return getNoAuthIdLock;
    }

    getNoAuthIdLock = (async () => {
        try {
            var item = localStorage.getItem('noAuthToken');
            if (item === null) {
                console.log("No Auth Token");
                return await setNewNoAuthToken();
            }

            var axiosResponse = await verifyNoAuthUserId(item);
            if (axiosResponse.data.valid) {
                console.log("Valid stored token");
                return item;
            } else {
                return await setNewNoAuthToken();
            }
        } finally {
            getNoAuthIdLock = null;
        }
    })();

    return getNoAuthIdLock;
}

export const setNoAuthId = (id: string) => {
    return localStorage.setItem('noAuthToken', id);
}

