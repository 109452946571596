import React, {useState} from 'react';
import '../App.css';
import {Box, CardContent, CardMedia, Grid, Typography} from '@mui/material';
import {Link} from "react-router-dom";
import {ReadMore} from "../core/components/ReadMore";
import AddToBagComponent from "../core/components/AddToBag";
import {BookPackage, BookPackageDetails} from "../services/model/Packages";
import PhotosSlideShow from "../core/components/PhotosSlideShow";
import {Helmet} from "react-helmet-async";
type Props = {
    pack: BookPackageDetails
}
const PackageDetailsElement = (props : Props) => {

    const [pack, setPack] = useState(props.pack);
    const mapToBookPackage = (details: BookPackageDetails): BookPackage => ({
        id: details.id,
        name: details.name,
        description: details.description,
        photoUrl: details.photoUrl,
        price: details.price,
        discountPrice: details.discountPrice,
        photos: details.photos,
        inStock: details.inStock,
        ageGroup: details.ageGroup,
        bookPackageTags: details.bookPackageTags,
        initialBooks: [],
        slug: details.slug
    });

    const bookPackage = mapToBookPackage(pack);

    return (
        <>
        <Helmet>
            <title>{pack.name}</title>

            <meta
                name="description"
                content={pack.description}
            />

            <link
                rel="canonical"
                href={`https://www.raiseareader.ro/packages/details/${pack.slug}`}
            />
        </Helmet>
        <div style={{width: "inherit"}}>
            <h2></h2>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Box sx={{position:"relative"}} >
                        <PhotosSlideShow photos={pack.photos} bookName={pack.name} hasResources={false}/>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CardContent>
                        <Typography variant="h1" className="book-name">{pack.name}</Typography>
                        <AddToBagComponent isDetailsPage={true} pack={bookPackage} />

                        <div className="info-banner-container">
                            {pack.ageCategories.map((ageCategory, index) =>
                                <div className="info-banner-column">

                                    <Link to={`/books/age-category/` + ageCategory?.slug}>
                                        <div
                                            className="info-banner-value-colored-section">{ageCategory?.name}</div>
                                    </Link>
                                    <div className="info-banner-label">Vârstă recomandată</div>
                                </div>
                            )}

                            <div className="info-banner-column">
                                <div
                                    className="info-banner-value-colored-section">{pack.initialBooks ? pack.initialBooks.length : ""}</div>
                                <div className="info-banner-label">Număr de cărți
                                </div>
                            </div>
                        </div>
                        <></>
                        <Typography variant="body1" color="textSecondary">
                            <ReadMore id="pack-desc" text={pack.description} amountOfWords={45} isHtml={true}/>
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
            {pack.initialBooks && pack.initialBooks.map((book, index) => {
                return (
                    <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <Box sx={{position:"relative"}} >
                            {book.photos && book.photos[0] && (
                                <Link to={`/books/details/` + book.slug}>
                                    <CardMedia
                                        component="img"
                                        image={book.photos[0].url}
                                        alt={book.name}
                                        sx={{maxHeight: 150, objectFit: 'contain'}}
                                        style={{
                                            padding: '20px',
                                            filter: 'drop-shadow(-50px 0px 10px rgba(205, 180, 219, 0.5))'
                                        }}
                                    />
                                </Link>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CardContent>
                            <Link to={`/books/details/` + book.slug}><Typography variant="h1" className="book-name">{book.name}</Typography></Link>
                            <Typography variant="body1" color="textSecondary">
                                <ReadMore id="book-desc" text={book.description} amountOfWords={30}/>
                            </Typography>
                        </CardContent>
                    </Grid>
                </Grid>

                )
            })}
        </div>
        </>
    );
}

export default PackageDetailsElement;