import React, {useEffect} from "react";
import {ShoppingBagOutlined} from "@mui/icons-material";
import {isAuthenticated} from "../../services/authStorageService";
import {getBagCountOnEvent} from "../../services/bagService";
import {Link} from "react-router-dom";

export default function ShoppingCartComponent() {

    const [bagCount, setBagCount] = React.useState(0);

    useEffect(()=> {
        if (isAuthenticated()) {
            // getUserProfile();
        }
        const handleStorageChange = () => {
            setBagCount(Number(getBagCountOnEvent()));
        };

        window.addEventListener('bagChange', handleStorageChange);

        return () => {
            window.removeEventListener('bagChange', handleStorageChange);
        };
    }, [isAuthenticated]);

    return (
        <>
            <Link to="/my-bag" style={{paddingBottom: '6px'}}>
                <ShoppingBagOutlined sx={{color: "var(--cefacem-dark-color)"}}></ShoppingBagOutlined>
            </Link>
            <span className="my-bag-count" style={{display: bagCount > 0 ? 'inline' : 'none'}}>
              {bagCount}
            </span>

        </>
    );
}