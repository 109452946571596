import React, {ChangeEvent, useCallback, useEffect, useRef, useState} from 'react';
import '../App.css';
import {Alert, Box, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {ColorDto} from "../services/model/ILibraryModel";
import {Link} from "react-router-dom";
import {getColors} from "../services/libraryService";
import {
    deleteItem,
    getUserBag,
    removeVoucherFromBag,
    setBagCountOnEvent,
    setNoteOnBag,
    setVoucherOnBag
} from "../services/bagService";
import {BagItem, UserBag} from "../services/model/IBagModel";
import {Button, Col, Modal, Row} from 'react-bootstrap'
import Form from "react-bootstrap/Form";
import BillingAddressHandler from "./myaccount/BillingAddressHandler";
import BillingAddressStaticShower from "./myaccount/BillingAddressStaticShower";
import {getBillingAddress, getDeliveryAddress} from "../services/userService";
import {BillingAddress, DeliveryAddress, PaymentMethod} from "../services/model/Payments";
import CardDetails from "./myaccount/CardDetails";
import {getPaymentMethods, pay, payNoAuth} from "../services/paymentsService";
import DeliveryAddressStaticShower from "./myaccount/DeliveryAddressStaticShower";
import DeliveryAddressHandler from "./myaccount/DeliveryAddressHandler";
import LeftRightEntry from "../core/components/LeftRightEntry";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {Delete, Message} from "@mui/icons-material";
import {getNoAuthId, isAuthenticated} from "../services/authStorageService";
import {
    deleteNoAuthItem,
    getNoAuthUserBag,
    removeNoAuthVoucherFromBag, setNoAuthNoteOnBag,
    setNoAuthVoucherOnBag
} from "../services/noAuthBagService";
import {getNoAuthBillingAddress, getNoAuthDeliveryAddress} from "../services/noAuthUserService";
import {Helmet} from "react-helmet-async";

function MyBag() {
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [userBag, setUserBag] = useState<UserBag>();
    const [colors, setColors] = useState<ColorDto[]>([]);
    const [voucher, setVoucher] = useState<string>('');
    const [notes, setNotes] = useState<string>('');
    const [voucherShow, setVoucherShow] = useState<boolean>(false);
    const [noteShow, setNoteShow] = useState<boolean>(false);
    const [update, setUpdate] = useState<boolean>(true);
    const voucherField = useRef<HTMLFormElement>(null);
    const [expandBilling, setExpandBilling] = useState(false);
    const [expandDelivery, setExpandDelivery] = useState(false);
    const [voucherAdded, setVoucherAdded] = useState(false);
    const [voucherFailed, setVoucherFailed] = useState(false);
    const [saveCard, setSaveCard] = useState<boolean>(true);
    const [nonDelivarable, setNotDelivarable] = useState<boolean>(false);

    const params = new URLSearchParams(window.location.search);

    const [billingAddress, setBillingAddress] = useState<BillingAddress>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        county: '',
        city: '',
        address: '',
        postCode: '',
    });
    const [deliveryAddress, setDeliveryAddress] = useState<DeliveryAddress>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        county: '',
        city: '',
        address: '',
        postCode: '',
    });
    const [cards, setCards] = useState<PaymentMethod>();
    const [selectedCardId, setSelectedCardId] = useState<string>();
    const [manualClose, setManualClose] = useState(false);


    function getUserBagPromise() {
        return isAuthenticated() ? getUserBag() : getNoAuthUserBag();
    }

    function getBillingAddressPromise() {
        return isAuthenticated() ? getBillingAddress() : getNoAuthBillingAddress();
    }

    function getDeliveryAddressPromise() {
        return isAuthenticated() ? getDeliveryAddress() : getNoAuthDeliveryAddress();
    }

    const getData = useCallback(async () => {
        try {
            await getNoAuthId();
            let colorsResponse = await getColors();
            setColors(colorsResponse.data);

            const response = await getUserBagPromise();
            setUserBag(response.data);
            setVoucher(response.data.voucher);

            getBillingAddressPromise().then(value => {
                setBillingAddress(value.data);
            }).catch(e => {});

            getDeliveryAddressPromise().then(value => {
                setDeliveryAddress(value.data);
                setNotDelivarable(value.data.country !== '' && value.data.country.toLowerCase() !== 'românia' && value.data.country.toLowerCase() !== 'romania');
            }).catch(e => {});

            if (isAuthenticated()) {
                getPaymentMethods(true).then(response => {
                    let data = response.data;
                    if (data !== undefined && data.length > 0 && data[0] !== undefined ) {
                        setCards(data[0]);
                    }
                });
            }

        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        if (update) {
            getData();
        }
        setUpdate(false);
    },[update])

    function getColor() {
        return colors[colors.length % 2]?.code;
    }

    function toggleSaveCard() {
        setSaveCard(!saveCard);
    }

    function getCurrentBillingAddress() {
        return billingAddress;
    }

    function getCurrentDeliveryAddress() {
        return deliveryAddress;
    }

    function deleteItemPromise(id: string) {
        return isAuthenticated() ? deleteItem(id) : deleteNoAuthItem(id);
    }

    function setVoucherOnBagPromise() {
        return isAuthenticated() ? setVoucherOnBag({voucherCode: voucher}) : setNoAuthVoucherOnBag({voucherCode: voucher});
    }

    function removeVoucherPromise() {
        return isAuthenticated() ? removeVoucherFromBag() : removeNoAuthVoucherFromBag();
    }

    function setNotePromise() {
        return isAuthenticated() ? setNoteOnBag({note: notes}) : setNoAuthNoteOnBag({note: notes});
    }


    function handleRemove(id: string) {
        deleteItemPromise(id).then(() => {
            getData();
            setBagCountOnEvent();
        }).catch((e) => {
            alert('Eroare la ștergere');
        })
    }

    function getRedirectLink(item: BagItem) {
        if (item.itemType === 'BOOK') {
            return '/books/details/' + item.slug;
        }
        return '';
    }


    const handleVoucherChange = (e : ChangeEvent<HTMLInputElement>) => {
        setVoucher(e.target.value);
    };

    const handleNotesChange = (e : ChangeEvent<HTMLInputElement>) => {
        setNotes(e.target.value);
    };

    function handleSetVoucher() {
        setVoucherOnBagPromise().then(value => {
            setVoucherAdded(true);
            setVoucherFailed(false);
        }).catch(e => {
            setVoucher('');
            voucherField.current?.reset();
            setVoucherAdded(false);
            setVoucherFailed(true);
        }).finally(() => {
            setUpdate(true);
        });
    }

    function handleRemoveVoucher() {
        removeVoucherPromise().then(value => {
            setVoucher('');
            setVoucherAdded(false);
            setVoucherFailed(false);
        }).catch(e => {console.log(e)}).finally(() => {
            setUpdate(true);
        });
    }

    function getUrl() {
        if (!window.location.origin){
            // For IE
            // @ts-ignore
            window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        }

        return  window.location.origin + window.location.pathname;
    }

    function getPayPromise() {
        return isAuthenticated() ? pay : payNoAuth;
    }

    function makePayment() {
        getPayPromise()({
            cardId: selectedCardId,
            itemId: userBag?.id,
            orderType: "BAG",
            saveCard: saveCard,
            config: {
                redirectUrl: getUrl() + '?openBagPayment=true',
                view: "DESKTOP"
            }
        }).then((value) => {
            if (selectedCardId !== undefined) {
                setShow(true);
            } else {
                window.location.href = value.data.formUrl
            }
        }).catch(() => alert("Eroare la efectuarea plății"))
            .finally(() => {
                setLoading(false);
                setUpdate(true);
            });
    }

    function handlePayment() {

        if (getCurrentBillingAddress().email === '') {
            setExpandBilling(true);
            setUpdate(true);
            return;
        }

        if (getCurrentDeliveryAddress().email === '') {
            setExpandDelivery(true);
            setUpdate(true);
            return;
        }
        if (getCurrentDeliveryAddress().country.toLowerCase() !== 'românia' && getCurrentDeliveryAddress().country.toLowerCase() !== 'romania') {
            setNotDelivarable(true);
            return;
        }

        setLoading(true)
        if (notes !== '') {
            setNotePromise().then(() => {makePayment()});
        } else {
            makePayment();
        }

    }
    function handleClose() {
        setManualClose(true);
        setShow(false);
    }

    function getShow() {
        return show || ((params.get('openBagPayment')  === 'true') && !manualClose);
    }

    function getDisplayVoucher() {
        return voucherShow ? 'flex' : 'none';
    }

    function toggleShowVoucher() {
        setVoucherShow(!voucherShow);
    }

    function getTransformVoucher() {
        return voucherShow? 'rotate(180deg)' : 'rotate(0deg)';
    }


    function getTransformNote() {
        return noteShow? 'rotate(180deg)' : 'rotate(0deg)';
    }

    function toggleShowNote() {
        setNoteShow(!noteShow);

    }

    function getDisplayNote() {
        return noteShow ? 'flex' : 'none';
    }

    function getTransportCost() {
        var transportCost = userBag?.items.find(item => item.itemType === 'TRANSPORT')?.salePrice;
        return transportCost ? transportCost + " lei" : "GRATUIT";
    }

    function getSubtotalPrice() {
        if (userBag) {
            var transportCost = userBag?.items.find(item => item.itemType === 'TRANSPORT')?.salePrice;
            return (userBag?.price - (transportCost !== undefined ? transportCost : 0));
        }
        return 0;
    }

    function getSubtotalDiscountedPrice() {
        if (userBag) {
            var transportCost = userBag?.items.find(item => item.itemType === 'TRANSPORT')?.salePrice;
            return (userBag?.discountedPrice - (transportCost !== undefined ? transportCost : 0));
        }
        return 0;
    }

    return (
      <>
          <Helmet>
              <title>Coș de cumpărături – Raise a Reader</title>
              <meta
                  name="description"
                  content="Aici găsești cărțile adăugate în coșul ta. Revino oricând pentru a continua explorarea și completarea comenzii."
              />
              <link rel="canonical" href="https://www.raiseareader.ro/my-bag" />
              <meta name="robots" content="noindex, nofollow" />
          </Helmet>

          {loading && <div className="loading-overlay">Vă rugam așteptați...</div>}

          <div className="App">
              <div id="wave" className="parent align-left top-section div-with-background">
                  <div className="small-container ">
                      <br/>
                      <h2>Coș de cumpărături</h2>
                  </div>
              </div>
              <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
              <div className="App-body fauna-one-regular ">
                  <div className="fauna-one-regular">
                      {userBag && userBag.items && userBag.items.length > 0 ? (
                          <>
                              <div className="parent">
                                  <Grid container spacing={2} style={{
                                      color: "var(--button-bg-color)",
                                      width: "100%",
                                      marginLeft: "0px",
                                      marginTop: "10px"
                                  }}>
                                      {userBag.items.filter(item => item.itemType === 'BOOK' || item.itemType === 'CHRISTMAS_PACKAGE' || item.itemType === 'PACKAGE').map((item) => (
                                          <Grid item xs={12} key={item.id} style={{
                                              backgroundColor: "var(--menu-background-color-faded)",
                                              borderRadius: "8px",
                                              marginBottom: '5px',
                                              padding: "10px",
                                              position:"relative"
                                          }}>
                                              <Box position={"absolute"} top={0} right={0}>
                                                  {item.id ?
                                                      <Button className="button-just-text" style={{padding: "0px"}}
                                                              onClick={() => handleRemove(item.id)}>
                                                          <Delete />
                                                      </Button>
                                                      :
                                                      <></>
                                                  }
                                              </Box>
                                              <Box display="flex" alignItems="center"
                                                   justifyContent="space-between" className={"responsive-flex"}>
                                                  <Box display="flex" alignItems="center"
                                                       justifyContent="flex-start" >
                                                      <Box display="flex" alignItems="center" justifyContent="center">
                                                          <Link to={getRedirectLink(item)}>
                                                              {item.photoUrl ? (
                                                                  <Box sx={{marginRight: 2}}>
                                                                      <img className='bookInPlan' src={item.photoUrl} alt={item.name} style={{
                                                                          margin: '10px'
                                                                      }}/>
                                                                      {/*{item.itemType === 'CHRISTMAS_PACKAGE' ?*/}
                                                                      {/*    <span style={{*/}
                                                                      {/*        color: "var(--menu-background-color)",*/}
                                                                      {/*        fontSize: "9px"*/}
                                                                      {/*    }}>* imagine cu titlu de prezentare</span>*/}
                                                                      {/*    :*/}
                                                                      {/*    <></>}*/}

                                                                  </Box>
                                                              ) : (
                                                                  <Typography variant="body2" style={{}}>No photos
                                                                      available</Typography>
                                                              )}
                                                          </Link>
                                                      </Box>
                                                      <Box display="flex"
                                                           justifyContent="space-evenly" flexDirection={"column"}>
                                                          <div>
                                                              <Typography gutterBottom>{item.name}</Typography>
                                                              <Typography gutterBottom>{item.subName}</Typography>
                                                          </div>

                                                          <Box>
                                                              {item.salePrice !== item.catalogPrice && item.salePrice !== undefined ?
                                                                  <div className="price">
                                                                      <s>{item.catalogPrice.toFixed(2)} lei</s>
                                                                      <strong> {item.salePrice.toFixed(2)} lei</strong>
                                                                  </div>
                                                                  :
                                                                  <div className="price">
                                                                      <strong>{item.catalogPrice.toFixed(2)} lei</strong>
                                                                  </div>
                                                              }
                                                          </Box>
                                                      </Box>
                                                  </Box>

                                              </Box>



                                          </Grid>
                                      ))}
                                  </Grid>
                              </div>
                              <div style={{
                                  padding: "20px",
                                  backgroundColor: "var(--menu-background-color-faded)",
                                  borderRadius: "8px",
                                  marginBottom: '5px',
                                  flexDirection: "column"
                              }}>

                                  {userBag.price === userBag.discountedPrice ?
                                      <div>

                                          <Row className="g-2">
                                              <LeftRightEntry type="h6" left="Sub-total"
                                                              right={getSubtotalPrice().toFixed(2) + " lei"}/>
                                          </Row>
                                          <Row className="g-2">
                                              <LeftRightEntry type="h6" left="Livrare (gratuită peste 200 lei)" right={getTransportCost()}/>
                                          </Row>
                                          <Row className="g-2">
                                              <LeftRightEntry type="h5" left="Total"
                                                              right={userBag.price.toFixed(2) + " lei"}/>
                                          </Row>
                                      </div>
                                      :
                                      <>
                                          <Typography variant="subtitle2"><i>Livrare gratuită peste 200 lei</i></Typography>
                                          {/*(gratuită peste 200 lei)*/}
                                          <Row className="g-2">

                                              <LeftRightEntry type="h6" left="Sub-total"
                                                              right={getSubtotalDiscountedPrice().toFixed(2) + " lei"} discounted={getSubtotalPrice().toFixed(2) + " lei"}/>

                                              {/*<LeftRightEntry type="h6" left="Sub-total"*/}
                                              {/*                right={getSubtotalPrice().toFixed(2) + " lei"} fontWeight={"bold"}/>*/}
                                          </Row>
                                          <Row className="g-2">
                                              <LeftRightEntry type="h6" left="Livrare" right={getTransportCost()}/>
                                          </Row>
                                          <Row className="g-2">
                                              <LeftRightEntry type="h5" left="Total"
                                                              right={userBag.discountedPrice.toFixed(2) + " lei"} fontWeight={"bold"}/>
                                          </Row>

                                          <Row className="g-2">
                                              <LeftRightEntry type="h6" left="Ai economisit"
                                                              right={((userBag.price - userBag.discountedPrice) * -1).toFixed(2) + " lei"}/>
                                          </Row>
                                      </>
                                  }
                              </div>
                              <div className={"voucher"}>
                                  <Row className="g-2">
                                      <Col xs={10} style={{paddingBottom: "5px"}} onClick={toggleShowVoucher}>
                                          <Typography variant="h6">Ai un voucher cadou?
                                          </Typography>
                                      </Col>
                                      <Col>
                                          <ArrowDropDownIcon sx={{fontSize: '2rem', transform: getTransformVoucher()}}/>
                                      </Col>
                                  </Row>
                                  <div style={{display: getDisplayVoucher(), flexDirection: "column"}}>
                                      <Row className="g-2">
                                          <Col xs={6}>
                                              <Form ref={voucherField}>
                                                  <Form.Control
                                                      type="text"
                                                      name="voucher"
                                                      placeholder="Voucher"
                                                      value={voucher}
                                                      onChange={handleVoucherChange}
                                                      required
                                                  />
                                              </Form>

                                          </Col>
                                          <Col>
                                              <Button variant="light"
                                                      onClick={handleSetVoucher}>Aplică</Button>
                                          </Col>
                                          <Col>
                                              <Button variant="light"
                                                      onClick={handleRemoveVoucher}>Șterge</Button>
                                          </Col>
                                      </Row>
                                      <Row>
                                          <Col>
                                              {
                                                  voucherAdded && <Alert severity="success">Cod aplicat cu succes.</Alert>
                                              }
                                              {
                                                  voucherFailed && <Alert severity="error">Cod invalid sau expirat!</Alert>
                                              }
                                          </Col>
                                      </Row>
                                  </div>
                              </div>

                              <div className={"voucher"}>
                                  <Row className="g-2">
                                      <Col xs={10} style={{paddingBottom: "5px"}} onClick={toggleShowNote}>
                                          <Typography variant="h6" >Notă comandă
                                          </Typography>
                                      </Col>
                                      <Col>
                                          <ArrowDropDownIcon sx={{fontSize: '2rem', transform: getTransformNote()}}/>
                                      </Col>
                                  </Row>
                                  <div style={{display:getDisplayNote(), flexDirection: "column"}}>
                                      <Row className="g-2">
                                          <Col xs={12}>
                                              <Form>
                                                  <Form.Control
                                                      as="textarea" style={{resize:"both"}}
                                                      rows={2}
                                                      name="notes"
                                                      placeholder="Notă"
                                                      value={notes}
                                                      onChange={handleNotesChange}
                                                      required
                                                  />
                                              </Form>
                                          </Col>
                                      </Row>
                                  </div>
                              </div>

                              <div className="parent">
                                  <div className="column-33 " style={{padding: "0px"}}>
                                      {expandBilling || getCurrentBillingAddress().email === '' ?
                                          <BillingAddressHandler userBillingAddress={getCurrentBillingAddress()}
                                                                 update={update} setExpand={setExpandBilling}
                                                                 setUpdate={setUpdate}/>
                                          :
                                          <BillingAddressStaticShower billingAddress={getCurrentBillingAddress()}
                                                                      setExpand={setExpandBilling}/>
                                      }
                                  </div>
                                  <div className="column-33 " style={{padding: "0px"}}>
                                      {expandDelivery || getCurrentBillingAddress().email !== '' && getCurrentDeliveryAddress().email === '' ?
                                          <DeliveryAddressHandler deliveryAddress={getCurrentDeliveryAddress()}
                                                                  update={update} setExpand={setExpandDelivery}
                                                                  setUpdate={setUpdate} setNotDeliverable={setNotDelivarable}/>
                                          :
                                          <DeliveryAddressStaticShower deliveryAddress={getCurrentDeliveryAddress()}
                                                                       setExpand={setExpandDelivery}/>
                                      }
                                  </div>
                                  <div className="column-33 border" style={{marginTop: "10px"}}>
                                      {cards && cards.userCards.map((card, index) => {
                                          return (
                                              <>
                                                  <div className="parent"
                                                       style={{
                                                           width: "100%",
                                                           border: "solid, 2px",
                                                           borderRadius: "30px",
                                                           justifyContent: "space-evenly",
                                                           borderColor: "var(--menu-background-color-faded)",
                                                           marginBottom: "5px",
                                                           cursor: "pointer"
                                                       }} onClick={() => setSelectedCardId(card.id)}>

                                                      <input
                                                          type="radio"
                                                          name="selectedCard"
                                                          value={card.id}
                                                          checked={selectedCardId === card.id}
                                                      />
                                                      <CardDetails card={card} includeMain={false}/>
                                                  </div>
                                              </>
                                          )
                                              ;
                                      })}
                                      <div className="parent"
                                           style={{
                                               border: "solid, 2px",
                                               borderRadius: "30px",
                                               borderColor: "var(--menu-background-color-faded)",
                                               justifyContent: "space-evenly",
                                               marginBottom: "5px",
                                               cursor: "pointer"
                                           }} onClick={() => setSelectedCardId(undefined)}>

                                          <input
                                              type="radio"
                                              name="selectedCard"
                                              value={undefined}
                                              checked={selectedCardId === undefined}

                                          />
                                          <Typography>Plată cu card nou</Typography>
                                          {isAuthenticated() && selectedCardId === undefined ?
                                              <FormControlLabel
                                                  label="Păstrează cardul pentru plăți viitoare"
                                                  control={
                                                      <Checkbox
                                                          checked={saveCard}
                                                          onChange={toggleSaveCard}
                                                      />
                                                  }
                                              />
                                              :
                                              <></>
                                          }
                                      </div>

                                  </div>
                              </div>
                              <div style={{margin: "10px"}}>
                                  { nonDelivarable && <Alert variant="standard" severity="error" >Ne cerem scuze, dar momentan nu efectuăm livrari în afara României.</Alert>}
                                  <Button className="button-just-text3 button-contact" onClick={handlePayment}>Confirmă
                                      și
                                      plătește</Button>
                              </div>
                          </>
                      ) : (
                          <Typography variant="body2">Coșul tău este gol</Typography>
                      )}
                  </div>
              </div>
          </div>

          <Modal
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered show={getShow()} onHide={handleClose}
          >
              <Modal.Header closeButton>
                  <Modal.Title>Îți mulțumim pentru comandă!</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable">
                  Tranzacția este în procesare la procesatorul de plăți. Verificați "Contul Meu - Comenzi" sau email-ul
                  pentru detalii.
              </Modal.Body>
              <Modal.Footer>

              </Modal.Footer>
          </Modal>

      </>
    );
}

export default MyBag;
