import React, {useCallback, useEffect, useState} from 'react';
import '../App.css';
import {Box, Grid, Typography} from "@mui/material";
import {ColorDto, LibraryDto} from "../services/model/ILibraryModel";
import {Link} from "react-router-dom";
import {downloadResourceFromAPI, getColors, getUserLibrary} from "../services/libraryService";
import {Helmet} from "react-helmet-async";

function MyLibrary() {
    const [library, setLibrary] = useState<LibraryDto>();
    const [colors, setColors] = useState<ColorDto[]>([]);
    const [loading, setLoading] = useState(false);


    const getData = useCallback(async () => {
        try {
            const response = await getUserLibrary();
            setLibrary(response.data);
            let colorsResponse = await getColors();
            setColors(colorsResponse.data);
        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        getData();
    },[])

    function getColor() {
        return colors[colors.length % 2]?.code;
    }

    function downloadResource(id: string, name: string) {
        setLoading(true);
        try {
            downloadResourceFromAPI(id).then(response => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = name + ".pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            }).finally(() => {setLoading(false)});

        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    }

    return (
      <>
          <Helmet>
              <title>Resursele mele educaționale – Raise a Reader</title>
              <meta
                  name="description"
                  content="Accesează ghidurile interactive și materialele educaționale pentru cărțile achiziționate, create să te ajute să crești un cititor curios și implicat."
              />
              <link rel="canonical" href="https://www.raiseareader.ro/my-library" />
              <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          {loading && <div className="loading-overlay">Vă rugam așteptați...</div>}

          <div className="App">
              <div id="wave" className="parent align-left top-section div-with-background">
                  <div className="small-container ">
                      <br/>
                      <h1>Resurse educaționale</h1>
                      <h5>Accesează ghidul și materialele create de noi, precum si resursele educaționale pentru cărțile achiziționate din librărie, create să te ajute în procesul de a crește un cititor.</h5>
                      <h5>Explorează-le și transformă fiecare sesiune de lectură într-o experiență interactivă și educativă!</h5>
                  </div>
              </div>
              <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
              <div className="App-body fauna-one-regular">
                  <div className="fauna-one-regular" style={{width:"100%"}}>
                      {library?.resources && library.resources.length > 0 ?
                          <Box id="sized-container" style={{marginBottom: "10px"}} border={4} borderColor={getColor} borderRadius="15px">
                              <Box display="flex" flexDirection="column" justifyContent="left" alignItems="flex-start" bgcolor={getColor} marginBottom="5px">
                                  <span className="span-bold-text" style={{color:"white", padding:"5px"}}>Resursele mele</span>
                              </Box>
                              <Grid container spacing={2}>
                                  {library?.resources
                                      .map((resource, index) => {
                                          return (
                                              <Grid item xs={6} sm={6} md={2.4} lg={2.4} key={resource.id}>
                                                  <Box>
                                                      <div className={"image_wrapper"} onClick={() => downloadResource(resource.id, resource.name)}>
                                                          {resource.photoUrl ? (
                                                              <Box  style={{cursor:"pointer"}} key={resource.photoUrl} sx={{
                                                                  marginBottom: 2,
                                                                  filter: 'drop-shadow(rgba(205, 180, 219, 0.5) 20px 30px 10px)'
                                                              }}>
                                                                  <img className='image_wrapper bookInPlan' src={resource.photoUrl}
                                                                       alt={resource.name} style={{
                                                                      maxHeight: '190px',
                                                                      maxWidth: '190px',
                                                                      objectFit: 'cover'
                                                                  }}/>
                                                              </Box>

                                                          ) : (
                                                              <Typography variant="body2">No photos available</Typography>
                                                          )}
                                                          <div className="fauna-one-regular">
                                                              <Typography variant="h6" gutterBottom>{resource.name}</Typography>
                                                          </div>
                                                          <div className="overlay overlay_2">
                                                              <h5><strong>Descarcă</strong></h5>
                                                          </div>
                                                      </div>
                                                  </Box>
                                              </Grid>
                                          )}
                                      )}
                              </Grid>
                          </Box>
                          :
                        <></>
                      }

                      {library?.orders
                          .map((order, index) => {
                              const color = colors[colors.length % index]?.code;
                              return (

                                  <Box id="sized-container" style={{marginBottom: "10px"}} border={4} borderColor={getColor} borderRadius="15px">
                                         <Box display="flex" flexDirection="column" justifyContent="left" alignItems="flex-start" bgcolor={getColor} marginBottom="5px">
                                                <span className="span-bold-text" style={{color:"white", padding:"5px"}}>{"Cărți din comanda numărul: " + order.order.displayableOrderNumber}</span>
                                             {order.plan ?
                                                 <span className="span-bold-text" style={{color:"white", padding:"5px"}}>{"Club de lectura " + order.plan.institution + " - " + order.plan.institutionGroup + " " + order.plan.startDate + " - " + order.plan.endDate}</span>
                                                 :
                                            <></>}
                                         </Box>
                                      <Grid container spacing={2}>
                                          {order?.books && order.books
                                              .filter((book) => book.id !== null)
                                              .map((book, index) => {
                                                  return (
                                                      <Grid item xs={6} sm={6} md={2.4} lg={2.4} key={book.id}>
                                                          <Box>
                                                              <div className={"image_wrapper"}>
                                                                  <Link to={`/books/details/` + book.slug}>
                                                                      {book.photoUrl ? (
                                                                          <Box key={book.photoUrl} sx={{
                                                                              marginBottom: 2,
                                                                              filter: 'drop-shadow(rgba(205, 180, 219, 0.5) 20px 30px 10px)'
                                                                          }}>
                                                                              <img className='image_wrapper bookInPlan' src={book.photoUrl}
                                                                                   alt={book.name} style={{
                                                                                  maxHeight: '190px',
                                                                                  maxWidth: '190px',
                                                                                  objectFit: 'cover'
                                                                              }}/>
                                                                          </Box>

                                                                      ) : (
                                                                          <Typography variant="body2">No photos available</Typography>
                                                                      )}
                                                                  </Link>
                                                                  <div className="fauna-one-regular">
                                                                      <Typography variant="h6" gutterBottom>{book.name}</Typography>
                                                                  </div>
                                                                  <div className="overlay overlay_2">
                                                                      <h5><strong>Vezi detalii</strong></h5>
                                                                  </div>
                                                              </div>
                                                          </Box>
                                                      </Grid>
                                                  )}
                                              )}
                                      </Grid>
                              </Box>
                              )}
                          )}
                  </div>
              </div>
          </div>

      </>
  )
      ;
}

export default MyLibrary;
