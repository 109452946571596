import {auth, http, httpNoAuth} from "../core/services/http";
import {LoginRequest, LogoutRequest} from "./model/LoginRequest";
import {LoginResponse} from "./model/LoginResponse";
import {RegisterRequest} from "./model/RegisterRequest";
import {SubscriptionModels, SubscriptionResponse} from "./model/SubscriptionModels";
import {ContactUsRequest} from "./model/ContactUsRequest";
import {KidProfile, NoAuthUser, UserProfile} from "./model/UserProfile";
import {GetCodeResetPasswordRequest, ResetPasswordRequest} from "./model/ResetPasswordRequest";
import {ChangePasswordRequest} from "./model/ChangePasswordRequest";
import {UUID} from "node:crypto";
import {BillingAddress, DeliveryAddress} from "./model/Payments";

export const login = (request : LoginRequest) =>
    auth.post<LoginResponse>('/login', request)

export const logout = (request : LogoutRequest) =>
    auth.post<LoginResponse>('/logout', request)

export const getResetPasswordCode = (request : GetCodeResetPasswordRequest) =>
    auth.put('/request-forgot-password', request)

export const setResetPassword = (request : ResetPasswordRequest) =>
    auth.put('/set-forgot-password', request)

export const register = (request : RegisterRequest) =>
    http.post('/users/register', request)

export const subscribe = (request : SubscriptionModels) =>
    http.post('/users/subscription', request)

export const contactUs = (request : ContactUsRequest) =>
    http.post('/users/subscription/contact-us', request)

export const changePassword = (request : ChangePasswordRequest) =>
    http.post<LoginResponse>('/users/change-password', request)

export const applyHere = function applyHere() {
    try {
        let request: SubscriptionModels = {
            annually: false,
            monthly: true,
            type: 'CLUB_MEMBER_SURVEY'
        }
        subscribe(request).catch(function (error) {console.log(error)});
    } catch (error) {
        console.log(error);
    }
}

export const getNoAuthUserId = () =>
    httpNoAuth.post<NoAuthUser>('/users/noauth/generate')

export const verifyNoAuthUserId = (id: string) =>
    httpNoAuth.get<NoAuthUser>(`/users/noauth/verify/${id}`)

export const getUserDetails = () =>
    http.get<UserProfile>('/users')

export const updateUserDetails = (userDetails: UserProfile) =>
    http.post<UserProfile>('/users/update-details', userDetails)

export const updateKidProfile = (kidId :UUID, kidDetails: KidProfile) =>
    http.post(`/users/kid/${kidId}`, kidDetails)

export const addKidProfile = (kidDetails: KidProfile) =>
    http.post(`/users/add-kid`, kidDetails)

export const removeKidProfile = (kidId :UUID) =>
    http.delete(`/users/remove-kid/${kidId}`)

export const getSubscriptions = () =>
    http.get<SubscriptionResponse[]>(`/users/subscription`)

export const getBillingAddress = () =>
    http.get<BillingAddress>(`/users/billing-address`)

export const updateBillingAddress = (billingAddress: BillingAddress) =>
    http.put<BillingAddress>(`/users/billing-address`, billingAddress)

export const getDeliveryAddress = () =>
    http.get<DeliveryAddress>(`/users/delivery-address`)

export const updateDeliveryAddress = (deliveryAddress: DeliveryAddress) =>
    http.put<DeliveryAddress>(`/users/delivery-address`, deliveryAddress)

export const copyDeliveryFromBilling = () =>
    http.post<DeliveryAddress>(`/users/delivery-address/copy`)