import '../../App.css';
import React, {useCallback, useEffect, useState} from "react";
import {getSubscriptions, getUserDetails, logout} from "../../services/userService";
import {Kindergarten, UserProfile} from "../../services/model/UserProfile";
import {Alert, Typography} from "@mui/material";
import MyProfile from "./MyProfile";
import {styled} from "@mui/material/styles";
import MuiAccordion, {AccordionProps} from "@mui/material/Accordion";
import MuiAccordionSummary, {AccordionSummaryProps} from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import KidProfileComponent from "./KidProfileComponent";
import {getKindergartens} from "../../services/institutionService";
import ProfileSubscriptions from "./ProfileSubscriptions";
import {SubscriptionResponse} from "../../services/model/SubscriptionModels";
import PaymentMethods from "./PaymentMethods";
import {Modal} from "react-bootstrap";
import {LogoutRequest} from "../../services/model/LoginRequest";
import {getRefreshToken, removeRefreshToken, removeToken, setAuthenticated} from "../../services/authStorageService";
import OrderDetailsList from "./OrderDetailsList";
import {Helmet} from "react-helmet-async";


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    textAlign: 'left',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },

    // todo fontFamily: 'Fauna One',
    // todo rounded corners
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowDropDownIcon sx={{ fontSize: '2rem' }}/>}
        // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    background: "var(--main-bg-color);",
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    background: "var(--main-bg-color);",
    paddingLeft: theme.spacing(3),
    textAlign: 'left',
    color: "var(--grey-color)",
}));


function MyAccount() {
    const [kindergartens, setKindergartens] =useState<Kindergarten[]>([]);
    const [subs, setSubs] =useState<SubscriptionResponse[]>([]);
    const [manualClose, setManualClose] = useState(false);
    const authResult = new URLSearchParams(window.location.search);

    const [userDetails, setUserDetails] = useState<UserProfile>({
        email: "",
        firstName: "",
        lastName: "",
        userType: ""
    });

    const [update, setUpdate] = useState(false);

    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])

    const getData = useCallback(async () => {
        try {
            const userResponse = await getUserDetails();
            setUserDetails(userResponse.data);
            const kg = await getKindergartens()
            setKindergartens(kg.data)
            const response = await getSubscriptions();
            setSubs(response.data);
        } catch (e) {
            console.error(e)
        } finally {

        }
    }, [])

    useEffect(() => {
        setUpdate(false);
        getData();

    }, [update])

    const handleLogout = async () => {
        let logoutRequest : LogoutRequest = {
            refreshToken : getRefreshToken()
        };
        const response = await logout(logoutRequest);

        removeToken();
        removeRefreshToken();
        setAuthenticated(false)
        // Redirect or perform other actions upon successful login
        window.location.reload();

    }

    function getCurrentUserDetails() {
        return userDetails;
    }

    const [expanded, setExpanded] = React.useState<string | false>(authResult.get('expandedPrev') || 'panel0');
    const [kidExpanded, setKidExpanded] = React.useState<string | false>(authResult.get('expandedKidPrev') || 'kidpanel0');
    const [subscriptionExpanded, setSubscriptionExpanded] = React.useState<string | false>(authResult.get('expandedSubsPrev') || 'subscriptionpanel0');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    const handleKidChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setKidExpanded(newExpanded ? panel : false);
        };

    const handleSubsChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setSubscriptionExpanded(newExpanded ? panel : false);
        };

    function getSubscriptionName(sub: SubscriptionResponse) {
        if (sub.type === 'CLUB_MEMBER_PILOT') {
            return 'Membru în club (Pilot)';
        }
        if (sub.type === 'CLUB_MEMBER') {
            return 'Membru în club';
        }
        if (sub.type === 'EXPLORER') {
            return 'EXPLORATOR';
        }
        return '';
    }

    function hasOverduePayment(sub: SubscriptionResponse) {
        return sub.payments.some(payment => payment.overdue);
    }

    function handleClose() {
        setManualClose(true);
    }

    function isPaymentMethods() {
        return authResult.get('openModalPaymentMethods') === 'true';
    }

    function isSubscription() {
        return authResult.get('openSubscriptionPayment') === 'true';
    }

    function getShow() {
        return (isPaymentMethods() || isSubscription()) && !manualClose;
    }

    return (
        <>
            <Helmet>
                <title>Contul meu – Raise a Reader</title>
                <meta
                    name="description"
                    content="Gestionează abonamentul tău și informațiile personale în contul Raise a Reader."
                />
                <link rel="canonical" href="https://www.raiseareader.ro/my-account" />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="App">
                <div id="wave" className="parent align-left top-section div-with-background">
                    <div className="small-container ">
                        <br/>
                        <h2>Bună {userDetails?.firstName} {userDetails?.lastName}</h2><br/>
                    </div>
                </div>
                <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
                <div className="App-body fauna-one-regular">

                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{width:"100%", maxWidth:"600px"}}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Detalii cont</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <MyProfile userDetails={getCurrentUserDetails()} />
                        </AccordionDetails>
                    </Accordion>
                    {(getCurrentUserDetails().kids?.length ?? 0) > 0 ?
                        <>
                            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{width:"100%", maxWidth:"600px"}}>
                                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                                    <Typography>Copiii mei</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {getCurrentUserDetails().kids?.map((kid, index) => {
                                        return (
                                            <Accordion expanded={kidExpanded === 'kidpanel' +index} onChange={handleKidChange('kidpanel' +index)} style={{width:"100%", maxWidth:"500px"}}>
                                                <AccordionSummary aria-controls={"kidpanel" + index + "d-content"} id={"panel" + index + "d-header"}>
                                                    <Typography>{kid.name ? kid.name : "Setează datele copilului"}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <KidProfileComponent
                                                        kidDetails={kid}
                                                        kindergartens={kindergartens}
                                                        update={setUpdate}
                                                        addKid={false}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>
                                        )
                                    } )}
                                    {/*<Accordion expanded={kidExpanded === 'kidpanelnew' } onChange={handleKidChange('kidpanelnew')} style={{width:"100%", maxWidth:"500px"}}>*/}
                                    {/*    <AccordionSummary aria-controls={"kidpanelnewd-content"} id={"panelnewd-header"}>*/}
                                    {/*        <Typography>Adaugă copil</Typography>*/}
                                    {/*    </AccordionSummary>*/}
                                    {/*    <AccordionDetails>*/}
                                    {/*        <KidProfileComponent*/}
                                    {/*            kidDetails={*/}
                                    {/*                {*/}
                                    {/*                    name: "",*/}
                                    {/*                    birthdate: "",*/}
                                    {/*                    groupDetails: {institutionId: "", institutionName: ""}*/}
                                    {/*                }*/}
                                    {/*            }*/}
                                    {/*            addKid={true}*/}
                                    {/*            kindergartens={kindergartens}*/}
                                    {/*            update={setUpdate}*/}
                                    {/*        />*/}
                                    {/*    </AccordionDetails>*/}
                                    {/*</Accordion>*/}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    :
                        <></>}

                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} style={{width:"100%", maxWidth:"600px"}}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <Typography>Abonamente</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {subs.map((sub, index) => {
                                return (
                                    <Accordion expanded={subscriptionExpanded === 'subscriptionpanel' +index} onChange={handleSubsChange('subscriptionpanel' +index)} style={{width:"100%", maxWidth:"500px"}}>
                                        <AccordionSummary aria-controls={"subscriptionpanel" + index + "d-content"} id={"subscriptionpanel" + index + "d-header"}>
                                            {hasOverduePayment(sub) ?
                                                <Alert severity="error" >
                                                    <Typography>{getSubscriptionName(sub)} - {sub.kidProfile?.name ? sub.kidProfile.name : "Setează datele copilului"} </Typography>
                                                </Alert>
                                                :
                                                    <Typography>{getSubscriptionName(sub)} - {sub.kidProfile?.name ? sub.kidProfile.name : "Setează datele copilului"} </Typography>

                                            }
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ProfileSubscriptions
                                                subscription={sub}
                                                setUpdate={setUpdate}
                                                expanded={expanded}
                                                expandedSubs={kidExpanded}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} style={{width:"100%", maxWidth:"600px"}}>
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                            <Typography>Modalități de plată</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PaymentMethods userDetails={getCurrentUserDetails()} parentUpdate={update} expanded={expanded}/>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} style={{width:"100%", maxWidth:"600px"}}>
                        <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
                            <Typography>Comenzi</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OrderDetailsList  parentUpdate={update} affiliate={false}/>
                        </AccordionDetails>
                    </Accordion>

                    {getCurrentUserDetails().userType === 'CREATOR' ?
                    <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} style={{width:"100%", maxWidth:"600px"}}>
                        <AccordionSummary aria-controls="panel6d-content" id="panel6d-header">
                            <Typography>Comenzi cod afiliere </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <OrderDetailsList  parentUpdate={update} affiliate={true}/>
                        </AccordionDetails>
                    </Accordion>
                        : <></>
                    }

                    <Accordion onChange={handleLogout} style={{width:"100%", maxWidth:"600px"}}>
                        <AccordionSummary expandIcon="">
                            <Typography>Log out</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>

            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={getShow()} onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Îți mulțumim pentru comandă!</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    Tranzacția este în procesare la procesatorul de plăți. Verificați contul sau email-ul pentru detalii.
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>

    );
}

export default MyAccount;
