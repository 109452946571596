import {http} from "../core/services/http";
import {Pagination} from "./model/Pagination";
import {Book, IBookThumbnail} from "./model/Books";
import {BookPackage, BookPackageDetails} from "./model/Packages";

export const getOldPublicBooks = (page: number, size : number) =>
    http.get<Pagination<IBookThumbnail>>('/books', {params: {page, size}})

export const getPublicBooks = (page: number, size : number, startAge : number) =>
    http.get<Pagination<IBookThumbnail>>('/books/sellable', {params: {page, size, startAge}})

export const getFilteredBooks = (filter:string, slug:string, page: number, size : number) =>
    http.get<Pagination<IBookThumbnail>>(`/books/filter/${filter}/${slug}`, {params: {page, size}})

export const getBookBySlug = (slug:string) =>
    http.get<Book>(`/books/details/${slug}`)

export const getBookPackages = (page: number, size : number) =>
    http.get<Pagination<BookPackage>>(`/packages/all`, {params: {page, size}})

export const getBookPackageDetails = (slug: string) =>
    http.get<BookPackageDetails>(`/packages/details/${slug}`)
