import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import {Link} from "react-router-dom";
import {applyHere, getUserDetails, logout} from "../../services/userService";
import {useCallback, useEffect} from "react";
import {
    getRefreshToken,
    isAuthenticated,
    removeRefreshToken,
    removeToken,
    setAuthenticated
} from "../../services/authStorageService";
import {LogoutRequest} from "../../services/model/LoginRequest";
import MySearch from "./MySearch";
import ShoppingCartComponent from "./ShoppingCart";

export default function BasicMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [hasGroup, setHasGroup] = React.useState(false);
    const [isTeacher, setIsTeacher] = React.useState(false);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const applyHereInternal = () => {
        setAnchorEl(null);
        applyHere();
    };
    useEffect(()=> {
        if (isAuthenticated()) {
            getUserProfile();
        }
    }, [isAuthenticated]);

    const getUserProfile = useCallback(async () => {
        const axiosResponse = await getUserDetails();
        const kids = axiosResponse.data.kids;
        if (kids != null && kids.length > 0) {
            if (kids.some(kid => kid.groupDetails && kid.groupDetails.id && kid.endSubscriptionDate && new Date(kid.endSubscriptionDate) > new Date())) {
                setHasGroup(true);
            }
        }
        const role = axiosResponse.data.userType;
        if (role === 'TEACHER') {
            setIsTeacher(true);
        }

    }, []);

    const handleLogout= async () => {
        setAnchorEl(null);

        let logoutRequest : LogoutRequest = {
            refreshToken : getRefreshToken()
        };
        const response = await logout(logoutRequest);

        removeToken();
        removeRefreshToken();
        setAuthenticated(false)
        // Redirect or perform other actions upon successful login
        window.location.reload();
    }

    return (
        <div className="action-menu-small" style={{alignItems: "self-end"}}>
            <MySearch color={"var(--cefacem-dark-color)"}/>

            <ShoppingCartComponent />

            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MenuIcon style={{ color: "var(--cefacem-dark-color)" }}  />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}><Link to="/" >Acasă</Link></MenuItem>
                {/*<MenuItem onClick={handleClose}><Link to="/christmas-packs" >Pachete de Crăciun</Link></MenuItem>*/}
                <MenuItem onClick={handleClose}><Link to="/book-store" >Librărie</Link></MenuItem>
                {hasGroup ?
                    <>
                        <MenuItem onClick={handleClose}><Link to="/my-plan" >Planul meu</Link></MenuItem>
                        {isTeacher ?
                            <MenuItem onClick={handleClose}><Link to="/teacher-groups" >Cluburile grădiniței mele</Link></MenuItem> : <></>
                        }
                    </>
                : isTeacher ?
                    <MenuItem onClick={handleClose}><Link to="/teacher-groups" >Cluburile grădiniței mele</Link></MenuItem> :
                    <MenuItem onClick={handleClose}><Link to="/book-clubs" >Cluburi</Link></MenuItem>
                }
                {isAuthenticated() ?
                    <>
                        <MenuItem onClick={handleClose}><Link to="/my-library" >Resurse educaționale</Link></MenuItem>
                        <MenuItem onClick={handleClose}><Link to="/my-account" >Contul meu</Link></MenuItem>
                    </>
                    :
                    <>
                        <MenuItem onClick={handleClose}><Link to="/login" >Intră în cont</Link></MenuItem>
                        <MenuItem onClick={applyHereInternal}><Link to="/contact-us">Aplică aici</Link></MenuItem>
                    </>
                }

            </Menu>
        </div>
    );
}
