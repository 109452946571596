import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../App.css';
import {Box, Grid, Typography, useMediaQuery, useTheme} from '@mui/material';
import {Link, useParams} from "react-router-dom";
import {IBookThumbnail} from "../services/model/Books";
import {getBookPackages, getFilteredBooks, getPublicBooks} from "../services/booksService";
import RegisterSection from "../core/components/RegisterSection";
import {
    Park,
} from "@mui/icons-material";
import {Button} from 'react-bootstrap'
import BookStoreCollection from "./BookStoreCollection";
import {BookPackage} from "../services/model/Packages";
import {Helmet} from "react-helmet-async";

function Books() {
    const {filter, slug} = useParams();
    const observer = useRef<IntersectionObserver>();

    const [babyBooks, setBabyBooks] = useState<IBookThumbnail[]>([]);
    const [twoYearsOldBooks, setTwoYearsOldBooks] = useState<IBookThumbnail[]>([]);
    const [threeYearsOldBooks, setThreeYearsOldBooks] = useState<IBookThumbnail[]>([]);
    const [fourYearsOldBooks, setFourYearsOldBooks] = useState<IBookThumbnail[]>([]);
    const [fiveYearsOldBooks, setFiveYearsOldBooks] = useState<IBookThumbnail[]>([]);
    const [enBooks, setEnBooks] = useState<IBookThumbnail[]>([]);
    const [usborneBooks, setUsborneBooks] = useState<IBookThumbnail[]>([]);
    const [educationalBooks, setEducationalBooks] = useState<IBookThumbnail[]>([]);
    const [educationalDevelopmentBooks, setEducationalDevelopmentBooks] = useState<IBookThumbnail[]>([]);
    const [totalNumber, setTotalNumber] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [packages, setPackages] = useState<BookPackage[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChristmasCampaign, setIsChristmasCampaign] = useState<boolean>(window.location.href.includes('/campaign/christmas'));

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    let itemsPerRow = isSm || isXs ? 6 : 4;

    const lastElementRef = useCallback(
        (node: HTMLDivElement) => {
            console.log("lastElementRef", node);
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting && page < totalPages ) {
                    console.log("entries", entries[0]);
                        setPage(page + 1);
                        console.log("pageInRef: ",page);

                        getData(page, itemsPerRow)
                } else {
                    console.log("not visible", entries[0]);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading]
    );

    async function populateBooks(setCollection: React.Dispatch<React.SetStateAction<IBookThumbnail[]>>, page: number, size: number, age: number) {
        const pageBooks = await getPublicBooks(page, size, age);
        setCollection(prevBooks => {
            const newBooks = pageBooks.data.content.filter((book: IBookThumbnail) => !prevBooks.some(b => b.id === book.id));
            return [...prevBooks, ...newBooks];
        });
    }

    async function populateUsborneBooks(setCollection: React.Dispatch<React.SetStateAction<IBookThumbnail[]>>, page: number, size: number) {
        var pageBooks = await getFilteredBooks('publisher', 'usborne', page, size);
        setCollection(prevBooks => {
            const newBooks = pageBooks.data.content.filter((book: IBookThumbnail) => !prevBooks.some(b => b.id === book.id));
            return [...prevBooks, ...newBooks];
        });
    }

    async function populateByGenre(setCollection: React.Dispatch<React.SetStateAction<IBookThumbnail[]>>, slug: string, page: number, size: number) {
        var pageBooks = await getFilteredBooks('genre', slug, page, size);
        setCollection(prevBooks => {
            const newBooks = pageBooks.data.content.filter((book: IBookThumbnail) => !prevBooks.some(b => b.id === book.id));
            return [...prevBooks, ...newBooks];
        });
    }

    async function populatePackages(setPackages: React.Dispatch<React.SetStateAction<BookPackage[]>>, page: number, size: number) {
        var pageBooks = await getBookPackages(page, size);
        setPackages(prevBooks => {
            const newBooks = pageBooks.data.content.filter((book: BookPackage) => !prevBooks.some(b => b.id === book.id));
            return [...prevBooks, ...newBooks];
        });
    }

    const getData = useCallback(async (page:number, size:number) => {
        setIsLoading(true);
        try {

            await populateBooks(setBabyBooks, page, size, 0);
            await populateBooks(setTwoYearsOldBooks, page, size, 2);
            await populateBooks(setThreeYearsOldBooks, page, size, 3);
            await populateBooks(setFourYearsOldBooks, page, size, 4);
            await populateBooks(setFiveYearsOldBooks, page, size, 5);
            await populateByGenre(setEnBooks, 'limbi-straine', page, size);
            await populateUsborneBooks(setUsborneBooks, page, size);
            await populateByGenre(setEducationalBooks, 'carte-educativa', page, size);
            await populateByGenre(setEducationalDevelopmentBooks, 'carte-de-dezvoltare-emotionala', page, size);
            await populatePackages(setPackages, page, size);

        } catch (e) {
                console.error(e)
        } finally {
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    },[])

    useEffect(() => {
        getData(page, itemsPerRow);
    },[])

    return (
        <>
            <Helmet>
                <title>Raise a Reader – Cărți pentru copii pe vârste și teme</title>
                <meta name="description"
                      content="Descoperă sute de cărți pentru copii, clasificate pe vârste, teme, autori și edituri. Alege ușor cartea potrivită pentru fiecare copil!"/>
                <link rel="canonical" href='https://www.raiseareader.ro/book-store'/>
            </Helmet>
            <div className="App">
                <div id="wave" className="parent align-left top-section div-with-background">
                    <div className="small-container ">
                        <br/>
                        {isChristmasCampaign ?
                            <div>
                                <h2>Descoperă cele mai frumoase și captivante cărți de Crăciun și de iarnă!</h2><br/>
                                <h5>În această perioadă magică, îți oferim o selecție atent aleasă de povești care să
                                    aducă farmec, căldură și bucurie sărbătorilor tale!
                                </h5>
                            </div>
                            : <div>
                                <h1>Librărie online cu cărți pentru copii – clasificate pe vârste, teme și autori</h1>
                                <h5>
                                    E o zi bună pentru a descoperi o carte grozavă pentru cei mici! Cu platforma
                                    noastră, ai la dispoziție <span className="low-highlight-purple">sute de cărți pentru copii</span>,
                                    ușor de explorat după vârstă, temă, autor sau editură.
                                </h5>
                            </div>
                        }
                    </div>
                </div>
                <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
                <div className="App-body fauna-one-regular">
                    <BookStoreCollection moreRequest={"/books/age/0"} ageLabel={"Cărți pentru 6+ luni"}
                                         books={babyBooks} visibleItems={4} cta={"Explorează mai multe titluri sigure și interactive pentru bebeluși"}/>
                    <BookStoreCollection moreRequest={"/books/age/2"} ageLabel={"Cărți pentru 2+ ani"}
                                         books={twoYearsOldBooks} visibleItems={4} cta={"Vezi întreaga colecție pentru micii exploratori de 2 ani"}/>
                    <BookStoreCollection moreRequest={"/books/age/3"} ageLabel={"Cărți pentru 3+ ani"}
                                         books={threeYearsOldBooks} visibleItems={4} cta={"Descoperă mai multe lecturi captivante pentru 3+ ani"}/>
                    <BookStoreCollection moreRequest={"/books/age/4"} ageLabel={"Cărți pentru 4+ ani"}
                                         books={fourYearsOldBooks} visibleItems={4} cta={"Explorează toate poveștile potrivite pentru 4+ ani"}/>
                    <BookStoreCollection moreRequest={"/books/age/5"} ageLabel={"Cărți pentru 5+ ani"}
                                         books={fiveYearsOldBooks} visibleItems={4} cta={"Vezi și celelalte titluri recomandate pentru 5+ ani"}/>
                    <BookStoreCollection moreRequest={"/books/publisher/usborne"} ageLabel={"Cărți Usborne"}
                                         books={usborneBooks} visibleItems={4} cta={"Descoperă mai multe titluri Usborne pentru toate vârstele"}/>
                    <BookStoreCollection moreRequest={"/books/genre/carte-educativa"} ageLabel={"Cărți non-ficțiune"}
                                         books={educationalBooks} visibleItems={4} cta={"Vezi colecția completă de cărți non-ficțiune pentru copii"}/>
                    <BookStoreCollection moreRequest={"/books/genre/carte-de-dezvoltare-emotionala"}
                                         ageLabel={"Dezvoltare emoțională"} books={educationalDevelopmentBooks}
                                         visibleItems={4} cta={"Explorează mai multe titluri care susțin înțelegerea emoțiilor"}/>
                    <BookStoreCollection moreRequest={"/books/genre/limbi-straine"} ageLabel={"Cărți în limba engleză"}
                                         books={enBooks} visibleItems={4} cta={"Vezi toată selecția de cărți în engleză pentru copii"}/>
                    {/*<PackagesCollection moreRequest={"/packages/all"} ageLabel={"Pachete de cărți"} packages={packages} visibleItems={4}/>*/}
                </div>
                {isChristmasCampaign ?
                    <Button className="button-contact">
                        <Link to={"/christmas-packs"} style={{color: "white"}}>
                            <Park sx={{color: "var(--button-hover-background)"}}/>
                            Achiziționează acum un pachet
                            <Park sx={{color: "var(--button-hover-background)"}}/>
                        </Link>
                    </Button>
                    :
                    <div style={{marginTop: "100px"}}>
                        <RegisterSection/>
                    </div>
                }
            </div>
        </>
    );
}

export default Books;
