import {auth, http, httpNoAuth} from "../core/services/http";
import {LoginRequest, LogoutRequest} from "./model/LoginRequest";
import {LoginResponse} from "./model/LoginResponse";
import {RegisterRequest} from "./model/RegisterRequest";
import {SubscriptionModels, SubscriptionResponse} from "./model/SubscriptionModels";
import {ContactUsRequest} from "./model/ContactUsRequest";
import {KidProfile, NoAuthUser, UserProfile} from "./model/UserProfile";
import {GetCodeResetPasswordRequest, ResetPasswordRequest} from "./model/ResetPasswordRequest";
import {ChangePasswordRequest} from "./model/ChangePasswordRequest";
import {UUID} from "node:crypto";
import {BillingAddress, DeliveryAddress} from "./model/Payments";

export const getNoAuthBillingAddress = () =>
    http.get<BillingAddress>(`/users/noauth/billing-address`)

export const updateNoAuthBillingAddress = (billingAddress: BillingAddress) =>
    http.put<BillingAddress>(`/users/noauth/billing-address`, billingAddress)

export const getNoAuthDeliveryAddress = () =>
    http.get<DeliveryAddress>(`/users/noauth/delivery-address`)

export const updateNoAuthDeliveryAddress = (deliveryAddress: DeliveryAddress) =>
    http.put<DeliveryAddress>(`/users/noauth/delivery-address`, deliveryAddress)

export const copyNoAuthDeliveryFromBilling = () =>
    http.post<DeliveryAddress>(`/users/noauth/delivery-address/copy`)