import {Box, CardMedia, Fade, IconButton, Modal} from "@mui/material";
import React from "react";
import {Photo} from "../../services/model/Books";
import {LibraryBooksRounded} from "@mui/icons-material";
export interface PhotosSlideShowProps {
    photos: Photo[];
    bookName: string;
    hasResources?: boolean;
}

export default function PhotosSlideShow(props: PhotosSlideShowProps) {

    const {
        photos,
        bookName,
        hasResources
    } = props

    const [mainPhoto, setMainPhoto] = React.useState<Photo | null>(photos.filter(photo => photo.main)[0] || null);

    const [open, setOpen] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState<number | null>(null);

    const handleOpen = (index: number) => {
        console.log("index", index);
        setCurrentIndex(index);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentIndex(null);
    };

    const handleNext = () => {
        if (currentIndex !== null) {
            setCurrentIndex((currentIndex + 1) % photos.length);
        }
    };

    const handlePrev = () => {
        if (currentIndex !== null) {
            setCurrentIndex((currentIndex - 1 + photos.length) % photos.length);
        }
    }

    const [touchStartX, setTouchStartX] = React.useState<number | null>(null);

    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        if (touchStartX !== null) {
            const touchEndX = e.changedTouches[0].clientX;
            if (touchStartX - touchEndX > 50) {
                handleNext();
            } else if (touchEndX - touchStartX > 50) {
                handlePrev();
            }
        }
        setTouchStartX(null);
    };
    return(
        <>
            {photos && mainPhoto && (
                <>
                    <CardMedia
                        component="img"
                        image={mainPhoto.url}
                        alt={bookName}
                        sx={{maxHeight: 350, objectFit: 'contain'}}
                        style={{
                            padding: '20px',
                            filter: 'drop-shadow(-50px 0px 10px rgba(205, 180, 219, 0.5))'
                        }}
                        onClick={() => handleOpen(photos.indexOf(mainPhoto))}

                    />
                    {photos.length > 1 && (
                    <div style={{ display: 'flex', justifyContent: photos.length > 4 ? 'flex-start' : 'center', marginTop: '10px', overflowX: 'auto'}}>
                        {photos.map((photo, index) => (
                            <CardMedia
                                key={index}
                                component="img"
                                image={photo.url}
                                alt={`${bookName} - ${index + 1}`}
                                sx={{ height: 80, width: 80, margin: '0 5px', opacity: 0.5, cursor: 'pointer' }}
                                onClick={() => setMainPhoto(photo)}
                            />
                        ))}
                    </div>
                    )}
                </>
            )}
            {hasResources ?
                <>
                    <LibraryBooksRounded sx={{
                        color: "var(--cefacem-color)",
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 3
                    }}/>
                </>
                : <></>
            }

            <Modal open={open} onClose={handleClose} style={{backgroundColor: "transparent", borderColor:"transparent"}}
                   closeAfterTransition
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '90vh' }} onClick={handleClose}
                     onTouchStart={handleTouchStart}
                     onTouchEnd={handleTouchEnd}>
                    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 3, color: "var(--cefacem-color)" }}>X</IconButton>
                    {currentIndex !== null && (
                        <>
                            <IconButton onClick={(e) => {e.stopPropagation(); handlePrev();}} sx={{ position: 'absolute', left: '10px', zIndex:3, color:"var(--cefacem-color)" }}>
                                &lt;
                            </IconButton>
                            <CardMedia
                                component="img"
                                image={photos[currentIndex].url}
                                alt={`${bookName} - ${currentIndex + 1}`}
                                onClick={(e) => e.stopPropagation()}
                                sx={{maxWidth: "80vw", maxHeight: "80vh",objectFit: 'contain', zIndex:3 }}
                            />
                            <IconButton onClick={(e) => {e.stopPropagation(); handleNext();}}  sx={{ position: 'absolute', right: '10px', zIndex:3, color:"var(--cefacem-color)" }}>
                                &gt;
                            </IconButton>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
}
