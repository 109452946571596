export type CategorySEO = {
    title: string;
    description: string;
    canonical: string;
    noIndex: boolean;
    internalLinks: {
        label: string;
        url: string;
    }[];
};

export const categorySEOData: Record<string, CategorySEO> = {
    "/age/0": {
        title: "Cărți pentru bebeluși – Primele lecturi",
        description: "Cărți cartonate, interactive și sigure pentru bebeluși peste 6 luni. Stimulează curiozitatea și creează conexiuni timpurii prin lectură.",
        canonical: "https://www.raiseareader.ro/books/age/0",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru 2+ ani", url: "/books/age/2" },
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
        ],
    },
    "/age/2": {
        title: "Cărți pentru copii începând de la 2 ani – Povești simple și jucăușe",
        description: "Povești scurte, ilustrații colorate și primele noțiuni pentru copiii de 2 ani. Lecturi care sprijină dezvoltarea limbajului și imaginației.",
        canonical: "https://www.raiseareader.ro/books/age/2",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" },
            { label: "Cărți Usborne", url: "/books/publisher/usborne" },
        ],
    },
    "/age/3": {
        title: "Cărți pentru copii începând de la 3 ani – Aventuri, emoții și descoperiri",
        description: "Lecturi captivante pentru copiii de 3 ani, care explorează prietenia, curiozitatea și învățarea prin joacă.",
        canonical: "https://www.raiseareader.ro/books/age/3",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru 4+ ani", url: "/books/age/4" },
            { label: "Cărți de dezvoltare emoțională", url: "/books/genre/carte-de-dezvoltare-emotionala" },
        ],
    },
    "/age/4": {
        title: "Cărți pentru copii începând de la 4 ani – Lecturi captivante",
        description: "Cărți pentru 4+ ani cu teme despre emoții, explorare și relații. Lecturi ideale pentru copii de grădiniță.",
        canonical: "https://www.raiseareader.ro/books/age/4",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru 5+ ani", url: "/books/age/5" },
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
        ],
    },
    "/age/5": {
        title: "Cărți pentru copii începând de la 5 ani – Pregătire blândă pentru școală",
        description: "Lecturi care dezvoltă empatia, vocabularul și gândirea logică. Povești potrivite pentru copiii pregătiți să pășească în lumea școlii.",
        canonical: "https://www.raiseareader.ro/books/age/5",
        noIndex: false,
        internalLinks: [
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
            { label: "Cărți de dezvoltare emoțională", url: "/books/genre/carte-de-dezvoltare-emotionala" },
        ],
    },
    "/publisher/usborne": {
        title: "Cărți Usborne – educație, distracție și calitate",
        description: "Explorează colecția Usborne: cărți educative, enciclopedii ilustrate și activități interactive pentru copii. Titluri premiate, cu clapete și ilustrații superbe.",
        canonical: "https://www.raiseareader.ro/books/publisher/usborne",
        noIndex: false,
        internalLinks: [
            { label: "Cărți în limba engleză", url: "/books/genre/limbi-straine" },
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
        ],
    },
    "/genre/carte-educativa": {
        title: "Cărți educative pentru copii – Învățare cu bucurie",
        description: "Cărți de non-ficțiune prietenoase pentru copii: natură, corpul uman, știință și multe altele. Învață prin joacă, explorare și curiozitate.",
        canonical: "https://www.raiseareader.ro/books/genre/carte-educativa",
        noIndex: false,
        internalLinks: [
            { label: "Cărți Usborne", url: "/books/publisher/usborne" },
            { label: "Cărți pentru 5+ ani", url: "/books/age/5" },
        ],
    },
    "/genre/carte-de-dezvoltare-emotionala": {
        title: "Cărți pentru dezvoltarea emoțională a copiilor",
        description: "Povești blânde care ajută copiii să identifice emoții, să comunice cu încredere și să înțeleagă lumea relațiilor.",
        canonical: "https://www.raiseareader.ro/books/genre/carte-de-dezvoltare-emotionala",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" },
            { label: "Cărți pentru 4+ ani", url: "/books/age/4" },
        ],
    },
    "/genre/limbi-straine": {
        title: "Cărți în limba engleză pentru copii – învățare naturală prin joc și poveste",
        description: "Cărți în engleză pentru copii: povești ilustrate, primele cuvinte și lecturi care dezvoltă vocabularul și încrederea în exprimare.",
        canonical: "https://www.raiseareader.ro/books/genre/limbi-straine",
        noIndex: false,
        internalLinks: [
            { label: "Cărți Usborne", url: "/books/publisher/usborne" },
            { label: "Cărți pentru 5+ ani", url: "/books/age/5" },
        ],
    },
    "/publisher/cartea-copiilor": {
        title: "Cărți Cartea Copiilor – literatură de calitate pentru cei mici",
        description: "Descoperă titlurile publicate de Cartea Copiilor: povești valoroase, traduceri excelente și autori recunoscuți. Cărți care trezesc curiozitatea și empatia copiilor.",
        canonical: "https://www.raiseareader.ro/books/publisher/cartea-copiilor",
        noIndex: false,
        internalLinks: [
            { label: "Cărți de dezvoltare emoțională", url: "/books/genre/carte-de-dezvoltare-emotionala" },
            { label: "Cărți pentru 4+ ani", url: "/books/age/4" }
        ]
    },

    "/publisher/portocala-albastra": {
        title: "Cărți Portocala Albastra – povești cu suflet pentru copii",
        description: "Explorează cărțile publicate de Portocala Albastra – povești blânde, pline de învățăminte și ilustrații delicate. Lecturi care susțin dialogul dintre copil și părinte.",
        canonical: "https://www.raiseareader.ro/books/publisher/portocala-albastra",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" },
            { label: "Cărți despre emoții", url: "/books/topic/emoții" }
        ]
    },

    "/publisher/arthur": {
        title: "Cărți Arthur – literatură contemporană pentru copii",
        description: "Cărțile Arthur aduc în prim-plan povești moderne, scrise de autori români și străini apreciați. Lecturi inteligente, amuzante și actuale pentru copii de toate vârstele.",
        canonical: "https://www.raiseareader.ro/books/publisher/arthur",
        noIndex: false,
        internalLinks: [
            { label: "Cărți scrise de autori români", url: "/books/topic/autori-români" },
            { label: "Cărți pentru 7+ ani", url: "/books/age/7" }
        ]
    },

    "/publisher/katartis": {
        title: "Cărți Katartis – lecturi terapeutice și povești blânde",
        description: "Descoperă cărțile Katartis – unelte valoroase pentru dezvoltarea emoțională și socială a copiilor. Povești care ajută la înțelegerea emoțiilor și a relațiilor.",
        canonical: "https://www.raiseareader.ro/books/publisher/katartis",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru dezvoltare emoțională", url: "/books/genre/carte-de-dezvoltare-emotionala" },
            { label: "Cărți pentru 5+ ani", url: "/books/age/5" }
        ]
    },

    "/publisher/frontiera": {
        title: "Cărți Frontiera – povești artistice și albume ilustrate",
        description: "Cărțile Frontiera se remarcă prin rafinament vizual și teme sensibile. Albume ilustrate, povești poetice și titluri valoroase pentru întreaga familie.",
        canonical: "https://www.raiseareader.ro/books/publisher/frontiera",
        noIndex: false,
        internalLinks: [
            { label: "Cărți ilustrate", url: "/books/genre/carte-ilustrata" },
            { label: "Cărți pentru 6+ ani", url: "/books/age/6" }
        ]
    },

    "/publisher/dph": {
        title: "Cărți DPH – educație și activități pentru copii curioși",
        description: "Explorează oferta DPH: enciclopedii, cărți de activități, povești educative și jocuri pentru copii. Lecturi practice care susțin învățarea prin joc.",
        canonical: "https://www.raiseareader.ro/books/publisher/dph",
        noIndex: false,
        internalLinks: [
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
            { label: "Cărți cu activități", url: "/books/genre/carte-de-activitati" }
        ]
    },

    "/publisher": {
        title: "Cărți de la editura {slug}",
        description: "Descoperă cele mai îndrăgite cărți de la editura {slug}. Povești captivante, titluri educative și resurse pentru copii de toate vârstele.",
        canonical: "https://www.raiseareader.ro/books/publisher/{slug}",
        noIndex: false,
        internalLinks: [
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
            { label: "Cărți pentru 5+ ani", url: "/books/age/5" },
        ],
    },
    "/author/julia-donaldson": {
        title: "Cărți scrise de Julia Donaldson – rime jucăușe și personaje memorabile",
        description: "Intră în lumea magică a Juliei Donaldson, autoarea îndrăgită a cărților cu rime ritmate și aventuri captivante. Descoperă titluri precum „Gruffalo”, „Maimuțica Bramburica” și multe altele, perfecte pentru cititul cu voce tare.",
        canonical: "https://www.raiseareader.ro/books/author/julia-donaldson",
        noIndex: false,
        internalLinks: [
            { label: "Cărți cu rime", url: "/books/topic/rime" },
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" }
        ]
    },
    "/author/eric-carle": {
        title: "Cărți scrise de Eric Carle – ilustrații inconfundabile și povești pentru cei mici",
        description: "Descoperă universul colorat al lui Eric Carle – autor și ilustrator al unor cărți clasice pentru copii, precum „Omida mâncăcioasă”. Lecturi perfecte pentru primele interacțiuni cu lumea poveștilor.",
        canonical: "https://www.raiseareader.ro/books/author/eric-carle",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru 6+ luni", url: "/books/age/0" },
            { label: "Cărți ilustrate", url: "/books/genre/carte-ilustrata" }
        ]
    },
    "/author/olina-ortiz": {
        title: "Cărți scrise de Olina Ortiz – povești sensibile pentru sufletul copiilor",
        description: "Explorează cărțile semnate de Olina Ortiz, autoare apreciată pentru povești care deschid conversații despre emoții, identitate și relația copilului cu lumea. Lecturi valoroase pentru timp de calitate împreună.",
        canonical: "https://www.raiseareader.ro/books/author/olina-ortiz",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru dezvoltare emoțională", url: "/books/genre/carte-de-dezvoltare-emotionala" },
            { label: "Cărți pentru 4+ ani", url: "/books/age/4" }
        ]
    },
    "/author/judith-kerr": {
        title: "Cărți scrise de Judith Kerr – clasice pentru copii cu tandrețe și umor",
        description: "Descoperă cărțile lui Judith Kerr, autoarea care a cucerit generații întregi cu personaje precum Mog și tigrul care a venit la ceai. Povești calde, amuzante și potrivite pentru cititul împreună.",
        canonical: "https://www.raiseareader.ro/books/author/judith-kerr",
        noIndex: false,
        internalLinks: [
            { label: "Cărți clasice pentru copii", url: "/books/genre/carte-clasica" },
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" }
        ]
    },
    "/author/richard-scarry": {
        title: "Cărți scrise de Richard Scarry – lumea veselă și detaliată a copiilor",
        description: "Explorează cărțile lui Richard Scarry, pline de umor, personaje haioase și ilustrații bogate în detalii. Lecturi captivante care îi ajută pe copii să învețe prin joacă despre meserii, orașe și bună purtare.",
        canonical: "https://www.raiseareader.ro/books/author/richard-scarry",
        noIndex: false,
        internalLinks: [
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" }
        ]
    },
    "/author/margaret-wise-brown": {
        title: "Cărți scrise de Margaret Wise Brown – povești blânde pentru somn ușor",
        description: "Descoperă magia cărților scrise de Margaret Wise Brown, autoarea clasicului „Noapte bună, lună”. Povești simple, tandre și pline de farmec, ideale pentru rutina de seară și momentele de liniște.",
        canonical: "https://www.raiseareader.ro/books/author/margaret-wise-brown",
        noIndex: false,
        internalLinks: [
            { label: "Cărți de citit seara", url: "/books/topic/citit-de-seară" },
            { label: "Cărți pentru 2+ ani", url: "/books/age/2" }
        ]
    },
    "/author/sam-usher": {
        title: "Cărți scrise de Sam Usher – aventuri poetice și ilustrate cu delicatețe",
        description: "Explorează cărțile lui Sam Usher – povești tandre despre relația dintre bunic și nepot, natură și imaginație. Ilustrații bogate și texte sensibile, ideale pentru citit împreună.",
        canonical: "https://www.raiseareader.ro/books/author/sam-usher",
        noIndex: false,
        internalLinks: [
            { label: "Cărți ilustrate", url: "/books/genre/carte-ilustrata" },
            { label: "Cărți pentru 4+ ani", url: "/books/age/4" }
        ]
    },
    "/author/kazuo-iwamura": {
        title: "Cărți scrise de Kazuo Iwamura – natură, familie și anotimpuri în povești ilustrate",
        description: "Descoperă cărțile lui Kazuo Iwamura, autorul care surprinde cu sensibilitate relațiile de familie și frumusețea naturii. Povești calde despre frați, anotimpuri și emoții trăite împreună.",
        canonical: "https://www.raiseareader.ro/books/author/kazuo-iwamura",
        noIndex: false,
        internalLinks: [
            { label: "Cărți despre familie", url: "/books/topic/familie" },
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" }
        ]
    },
    "/author/sven-nordqvist": {
        title: "Cărți scrise de Sven Nordqvist – aventurile lui Pettson și Findus",
        description: "Descoperă universul plin de umor și imaginație creat de Sven Nordqvist. Cărțile cu Pettson și motanul Findus sunt ideale pentru citit împreună, oferind povești amuzante și ilustrații bogate în detalii.",
        canonical: "https://www.raiseareader.ro/books/author/sven-nordqvist",
        noIndex: false,
        internalLinks: [
            { label: "Cărți amuzante", url: "/books/topic/umor" },
            { label: "Cărți pentru 5+ ani", url: "/books/age/5" }
        ]
    },
    "/author/giles-andreae": {
        title: "Cărți scrise de Giles Andreae – rime vesele și mesaje emoționante",
        description: "Descoperă cărțile lui Giles Andreae, pline de rime jucăușe și învățăminte despre curaj, prietenie și acceptare. Povești optimiste, perfecte pentru cititul cu voce tare și discuții despre emoții.",
        canonical: "https://www.raiseareader.ro/books/author/giles-andreae",
        noIndex: false,
        internalLinks: [
            { label: "Cărți cu rime", url: "/books/topic/rime" },
            { label: "Cărți pentru dezvoltare emoțională", url: "/books/genre/carte-de-dezvoltare-emotionala" }
        ]
    },
    "/author/roger-duvoisin": {
        title: "Cărți scrise de Roger Duvoisin – clasice ilustrate cu farmec atemporal",
        description: "Explorează cărțile lui Roger Duvoisin, autor și ilustrator al unor povești clasice pentru copii. Ilustrații expresive și teme blânde despre natură, prietenie și descoperirea sinelui.",
        canonical: "https://www.raiseareader.ro/books/author/roger-duvoisin",
        noIndex: false,
        internalLinks: [
            { label: "Cărți clasice pentru copii", url: "/books/genre/carte-clasica" },
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" }
        ]
    },
    "/author": {
        title: "Cărți pentru copii scrise de {slug}",
        description: "Descoperă cele mai frumoase cărți pentru copii scrise de {slug}. Povești captivante, personaje memorabile și lecturi pline de emoție.",
        canonical: "https://www.raiseareader.ro/books/author/{slug}",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" },
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
        ],
    },
    "/illustrator": {
        title: "Cărți pentru copii ilustrate de {slug}",
        description: "Descoperă cele mai frumoase cărți pentru copii ilustrate de {slug}. Povești captivante, personaje memorabile și lecturi pline de emoție.",
        canonical: "https://www.raiseareader.ro/books/illustrator/{slug}",
        noIndex: true,
        internalLinks: [
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" },
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
        ],
    },
    "/topic": {
        title: "Cărți pentru copii despre {slug}",
        description: "Explorează cărți pentru copii care abordează tema {slug}. Lecturi potrivite pentru conversații valoroase și învățare prin poveste.",
        canonical: "https://www.raiseareader.ro/books/topic/{slug}",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru dezvoltarea emoțională", url: "/books/genre/carte-de-dezvoltare-emotionala" },
            { label: "Cărți pentru 4+ ani", url: "/books/age/4" },
        ],
    },
    "/collection": {
        title: "Cărți pentru copii din seria {slug}",
        description: "Descoperă toate cărțile din colecția {slug}, potrivite pentru citit împreună și dezvoltarea pasiunii pentru lectură. Serii captivante, personaje recurente și aventuri de neuitat.",
        canonical: "https://www.raiseareader.ro/books/collection/{slug}",
        noIndex: false,
        internalLinks: [
            { label: "Cărți pentru 5+ ani", url: "/books/age/5" },
            { label: "Cărți Usborne", url: "/books/publisher/usborne" },
        ],
    },
    "/search": {
        title: "Rezultate pentru: {slug}",
        description: "Vezi toate cărțile pentru copii care corespund căutării „{slug}”. Explorează titluri potrivite pe vârste, teme, autori sau edituri.",
        canonical: "https://www.raiseareader.ro/books/search/{slug}",
        noIndex: true,
        internalLinks: [
            { label: "Cărți educative", url: "/books/genre/carte-educativa" },
            { label: "Cărți pentru 3+ ani", url: "/books/age/3" },
        ],
    }
};
