import React, {useEffect} from 'react';
import reading from '../assets/cefacem/reading_new.png';
import research from '../assets/cefacem/research_new.png';
import books from '../assets/cefacem/books_1.png';
import illustration1 from '../assets/illustrations/1.png';
import illustration2 from '../assets/illustrations/2.png';
import illustration3 from '../assets/illustrations/3.png';
import illustration4 from '../assets/illustrations/4.png';
import '../App.css';
import BigTagContainer from "../core/components/BigTagContainer";
import TitleSpan from "../core/components/TitleSpan";
import CustomizedAccordions from "../core/components/CustomAccordion";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ActionSection from "../core/components/ActionSection";
import {Link} from "react-router-dom";
import SavingsIcon from '@mui/icons-material/Savings';
import {AccessAlarm, AutoStories, Favorite, LocalShipping, MenuBook} from "@mui/icons-material";
import {Grid} from "@mui/material";
import {Helmet} from "react-helmet-async";

function App() {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
  return (
      <>
          <Helmet>
              <title>Librărie online - Citim împreună, creștem împreună - cărți pentru copii - cluburi</title>
              <meta name="description"
                    content="Librărie online. Te ajutăm să descoperi cărți pentru copii.
                Cluburi pentru lectură în familie chiar în grădinița voastră!
                "/>
              <link rel="canonical" href='https://www.raiseareader.ro'/>
          </Helmet>
          <div className="App">
              <div id="carousel-big" className="banner banner-big">
              {/*onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}*/}
              <Carousel showArrows={false} autoPlay={true} animationHandler={"slide"} axis={"horizontal"}
                        infiniteLoop={true} showThumbs={false} showStatus={false}>
                  <div>
                      <div className="div-with-background parent no-wrap">
                          <div className="column-33 rotate cover-align-start">
                              <img src={illustration1} className="big-cover-illustration" alt="illustration1"/>
                          </div>
                          <div className="column-33 top-section">
                              <h1><strong>Te susținem în eforturile tale de a crește un mic cititor!</strong></h1>
                              {/*<h1><strong>Oferim suport și îndrumare părinților pentru a crește cititori încrezători și entuziaști.</strong></h1>*/}
                          </div>
                          <div className="column-33 cover-align-start ">
                              <img src={illustration1} className="big-cover-illustration" alt="illustration1"/>
                          </div>
                      </div>
                      <div className="gradient-wave-bg rotate" style={{marginTop: '-20px'}}></div>
                  </div>
                  <div>
                      <div className="div-with-background parent no-wrap">
                          <div className="column-33 rotate cover-align-start">
                              <img src={illustration4} className="big-cover-illustration" alt="illustration4"/>
                          </div>
                          <div className="column-33 top-section">
                              <h1><strong>Credem că cititorii de astăzi sunt liderii de mâine.</strong></h1>
                          </div>
                          <div className="column-33 cover-align-start ">
                              <img src={illustration4} className="big-cover-illustration" alt="illustration4"/>
                          </div>
                      </div>
                      <div className="gradient-wave-bg" style={{marginTop: '-20px'}}></div>
                  </div>
                  <div>
                      <div className="div-with-background parent no-wrap">
                          <div className="column-33 cover-align-end">
                              <img src={illustration3} className="big-cover-illustration" alt="illustration3"/>
                          </div>
                          <div className="column-33 top-section">
                              <h1><strong>Oferim cărți atent alese care dezvoltă limbajul, imaginația, gândirea
                                  critică și simțul estetic.</strong></h1>
                          </div>
                          <div className="column-33 rotate cover-align-end ">
                              <img src={illustration3} className="big-cover-illustration" alt="illustration3"/>
                          </div>
                      </div>
                      <div className="gradient-wave-bg rotate" style={{marginTop: '-20px'}}></div>
                  </div>
                  <div>
                      <div className="div-with-background parent no-wrap">
                          <div className="column-33 cover-align-end">
                              <img src={illustration2} className="big-cover-illustration" alt="illustration2"/>
                          </div>
                          <div className="column-33 top-section">
                              <h1><strong>Încurajăm citirea interactivă oferind un set de întrebări și activități pe
                                  baza cărților.</strong></h1>
                          </div>
                          <div className="column-33 rotate cover-align-end">
                              <img src={illustration2} className="big-cover-illustration" alt="illustration2"/>
                          </div>
                      </div>
                      <div className="gradient-wave-bg" style={{marginTop: '-20px'}}></div>
                  </div>
                  <div>
                      <div className="div-with-background parent no-wrap">
                          <div className="column-33 rotate cover-align-start">
                              <img src={illustration1} className="big-cover-illustration" alt="illustration1"/>
                          </div>
                          <div className="column-33 top-section">
                              <h1><strong>Oferim suport și îndrumare părinților pentru a crește cititori încrezători și
                                  entuziaști.</strong></h1>
                          </div>
                          <div className="column-33 cover-align-start ">
                              <img src={illustration1} className="big-cover-illustration" alt="illustration1"/>
                          </div>
                      </div>
                      <div className="gradient-wave-bg rotate" style={{marginTop: '-20px'}}></div>
                  </div>
              </Carousel>
          </div>
          <div id="carousel-small" className="banner-small">
              <Carousel showArrows={false} autoPlay={true} animationHandler={"slide"} axis={"horizontal"}
                        infiniteLoop={true} showThumbs={false} showStatus={false}>
                  <div>
                      <div className="div-with-background banner-image">
                          <div className="rotate">
                              <img src={illustration1} className="small-cover-illustration" alt="illustration1"/>
                          </div>
                          <div className="top-section">
                              <div>
                                  <h1><strong>Te susținem în eforturile tale de a crește un mic cititor!</strong></h1>
                              </div>
                          </div>
                      </div>
                      <div className="gradient-wave-bg rotate" style={{marginTop: '-50px'}}></div>
                  </div>
                  <div>
                      <div className="div-with-background banner-image">
                          <div className="rotate">
                              <img src={illustration4} className="small-cover-illustration" alt="illustration4"/>
                          </div>
                          <div className="top-section">
                              <div>
                                  <h1><strong>Credem că cititorii de astăzi sunt liderii de mâine.</strong></h1>
                              </div>
                          </div>
                      </div>
                      <div className="gradient-wave-bg"></div>
                  </div>
                  <div>
                      <div className="div-with-background banner-image">
                          <div>
                              <img src={illustration3} className="small-cover-illustration" alt="illustration3"/>
                          </div>
                          <div className="top-section">
                              <div>
                                  <h1><strong>Oferim cărți atent alese care dezvoltă limbajul, imaginația, gândirea
                                      și simțul estetic.</strong></h1>
                              </div>
                          </div>
                      </div>
                      <div className="gradient-wave-bg rotate"></div>
                  </div>
                  <div>
                      <div className="div-with-background banner-image">
                          <div>
                              <img src={illustration2} className="small-cover-illustration" alt="illustration2"/>
                          </div>
                          <div className="top-section">
                              <div>
                                  <h1><strong>Încurajăm citirea interactivă oferind un set de întrebări și activități pe
                                      baza cărților.</strong></h1>
                              </div>
                          </div>
                      </div>
                      <div className="gradient-wave-bg"></div>
                  </div>
                  <div>
                      <div className="div-with-background banner-image">
                          <div className="rotate">
                              <img src={illustration1} className="small-cover-illustration" alt="illustration1"/>
                          </div>
                          <div className="top-section">
                              <div>
                                  <h1><strong>Oferim suport și îndrumare părinților pentru a crește cititori
                                      încrezători.</strong></h1>
                              </div>
                          </div>
                      </div>
                      <div className="gradient-wave-bg rotate" style={{marginTop: '-50px'}}></div>
                  </div>
              </Carousel>
          </div>
          <div className="App-body">

              <div id="cefacem" className="parent">

                  <header style={{"width": "100%"}}>
                      <TitleSpan
                          text={"Ce facem"}
                          class="cefacem"/>
                  </header>
                  <div style={{"display": "flex", "alignItems": "center"}} className="parent">
                      <BigTagContainer
                          content="<span class='title-low-highlight'><strong>Dezvoltăm cluburi pentru lectură în familie</strong></span><br/> chiar în grădinița voastră!"
                          contentDetails="Te introducem în rețeaua de părinți și copii cititori, iar tu nu trebuie să cumperi cărți noi în fiecare lună! În cadrul cluburilor, fiecare membru are acces la câte un nou set de cărți pe care să le exploreze timp de o lună. Noi facilităm schimbul de cărți, iar tu te bucuri constant de lecturi captivante și educative!"
                          overlap={books}/>

                      <BigTagContainer
                          content="<span class='title-low-highlight'><strong>Te ajutăm să descoperi, prin librărie,</strong></span> <br/>cărți pentru copilul tău."
                          contentDetails="Alegerea cărților potrivite poate fi copleșitoare, dar librăria noastră face lucrurile mult mai ușoare!
                          Îți dăm acces la o selecție valoroasă de sute de titluri atent alese, pe care le poți filtra cu ușurință în funcție de vârsta și interesele copilului tău."
                          overlap={research}/>

                      <BigTagContainer
                          content="<span class='title-low-highlight'><strong>Îți oferim suportul de care ai nevoie pentru a crește</strong></span> un copil curios și perseverent! "
                          contentDetails="Vrem să aducem claritate în procesul educațional pe care îl parcurge copilul tău mic. De aceea, în platforma noastră găsești resurse adiționale pentru lecturi interactive: sugestii de dialog și idei de activități pe baza cărților citite, articole bazate pe studii științifice și ghiduri practice."
                          overlap={reading}/>
                  </div>
              </div>
          </div>

          <div className="gradient-wave-bg flip" style={{transform: 'rotate(180deg)'}}></div>
          <div id="wave2" className="parent align-left top-section div-with-background" style={{marginTop: '-5px'}}>
              <div className="small-container " style={{textAlign: 'center'}}>
                  <h2>Avem sute de cărți și vrem să te ajutăm și pe tine <br/>să îi faci pe copiii tăi să <span
                      className="low-highlight-purple">IUBEASCĂ</span> cititul.</h2><br/>
                  <h5>
                      <Link to="/book-store">
                          <div className="button button-contact"
                               style={{marginLeft: '10px', marginTop: '10px', width: "fit-content"}}>
                              <span>Hai în librărie</span>
                          </div>
                      </Link>
                  </h5>

              </div>
          </div>
          <div className="gradient-wave-bg" style={{marginTop: '-20px', transform: 'rotate(360deg)'}}></div>

          {/*<div className="App">*/}
              <div className="App-body">
                  <div id="cumfacem" className="parent" style={{marginTop: "60px"}}>
                      <header style={{"width": "100%"}}>
                          <TitleSpan
                              text={"Cluburi pentru lectură în familie"}
                              class="cumfacem"/>
                          {/*<img src={cumFacem}/>*/}
                      </header>
                      <div className="span-minor-text align-left">
                          <p>Atunci când devii membrul unui club creat de noi în grădinița ta, primești acces
                              la cea mai frumoasă rețea de părinți și copii cititori!</p>
                          <p>Te conectezi cu alți părinți cu
                              care împărtășești bucuria lecturii, în timp ce copilul tău descoperă în fiecare lună un
                              set de
                              cărți uimitoare pe care îi va plăcea să le explorați împreună.
                          </p>

                          <p>
                              <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                      <LocalShipping sx={{color: "var(--cumfacem-color)"}}/> Faci schimbul de cărți
                                      chiar în cadrul grădiniței.
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                      <AutoStories sx={{color: "var(--cumfacem-color)"}}/> Primești planuri
                                      personalizate cu cărți atent alese.
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                      <MenuBook sx={{color: "var(--cumfacem-color)"}}/> Ai acces la materiale care
                                      susțin citirea interactivă.
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                      <AccessAlarm sx={{color: "var(--cumfacem-color)"}}/> Te simți grozav că ai
                                      prioritizat citirea pe pilot automat!
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                      <SavingsIcon sx={{color: "var(--cumfacem-color)"}}/> Ai acces la o bibliotecă
                                      valoroasă pentru care plătești cu până la 70% mai puțin
                                      decât dacă ai cumpăra toate cărțile din club pe cont propriu!
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                      <Favorite sx={{color: "var(--cumfacem-color)"}}/> Te conectezi cu copilul tău și
                                      îl ajuți să își dezvolte pasiunea pentru lectură.
                                  </Grid>
                              </Grid>
                          </p>
                      </div>

                  </div>
              </div>
          {/*</div>*/}

          <div className="gradient-wave-bg-orange flip"
               style={{marginTop: '-100px', transform: 'rotate(180deg)'}}></div>

          <div id="wave2" className="parent align-left top-section orange-background" style={{marginTop: '-5px'}}>
              <div className="small-container " style={{textAlign: 'center'}}>
                  <h2 style={{color: 'var(--cumfacem-dark-color)'}}>Avem sute de cărți și vrem să te ajutăm și pe tine <br/>să îi faci pe copiii tăi să <span
                      className="low-highlight-orange">IUBEASCĂ</span> cititul.</h2><br/>

                  <Link to="/book-clubs">
                      <div className="button button-contact button-contact-orange"
                           style={{marginLeft: '10px', width: "fit-content"}}>
                          <span>Descoperă cluburile</span>
                          {/*<span>Află cum funcționează<br/> cluburile de lectură</span>*/}
                      </div>
                  </Link>
          </div>
          </div>
          <div className="gradient-wave-bg-orange" style={{marginTop: '-10px'}}></div>


          <div className="App">
              <div className="App-body">
                  <div id="decefacem" className="parent" style={{marginTop: "60px"}}>
              <TitleSpan
                  text={"De ce o facem"}
                  class="decefacem" />
              <div className="small-container">
                  <h6 className="fauna-one-regular span-minor-text-without-padding">
                      Înțelegem beneficiile cititului în brațele părinților, de aceea te ajutăm să faci din
                      citit o activitate îndrăgita, ușurându-ți procesul de selecție și achiziție.
                  </h6>
              </div>


              <div style={{width: "90%", display: "inline-flex", justifyContent: "center", flexWrap: "wrap"}}>
                  <TitleSpan
                      text={"Gândire critică"}
                      class="dece-detalii"/>
                  <TitleSpan
                      text={"Limbaj"}
                      class="dece-detalii"/>
                  <TitleSpan
                      text={"Imaginație"}
                      class="dece-detalii"/>
                  <TitleSpan
                      text={"Simț estetic"}
                      class="dece-detalii"/>
              </div>

              <div style={{width: "90%", display: "inline-flex", justifyContent: "center", flexWrap: "wrap"}}>

                  <TitleSpan
                      text={"Atenție și concentrare"}
                      class="dece-detalii"/>
                  <TitleSpan
                      text={"Empatie"}
                      class="dece-detalii"/>
              </div>
              <div className="small-container">
                  {/*<section>*/}
                  <h6 className="fauna-one-regular span-minor-text-without-padding">
                      <div style={{paddingBottom: "2rem"}}>
                          Cititul copiilor preșcolari are implicații complexe asupra dezvoltării.
                      </div>
                      <div>
                                  <span
                                      className="span-bold-text">Nivelul limbajului reflectă nivelul gândirii</span> și
                          nu există activitate care să aibă un impact mai mare asupra dezvoltării limbajului ca cititul.
                      </div>
                  </h6>
                  {/*</section>*/}
              </div>
          </div>

          <div id="incepe" className="parent">
              <header style={{"width": "100%"}}>
                  <TitleSpan
                      text={"Începe aventura " +
                          " ACUM"}
                      class="aventura"/>
              </header>

              <div className="small-container">
                  <section>
                      <h5 className="fauna-one-regular span-minor-text-without-padding">
                          Bucură-te de livrare convenabilă în cadrul grădiniței, planuri personalizate cu cărți atent
                          alese și materiale care susțin cititul fiecărei cărți.
                      </h5>
                  </section>
                  <section>
                      <h5 className="fauna-one-bold span-minor-text">Zero efort în planificare, zero
                          scuze,&nbsp;
                          <span className="low-highlight">read and grow</span>.
                      </h5>
                  </section>
              </div>
          </div>
      </div>
      </div>

    <ActionSection/>

    <div id="faq" className="parent faq-div">
        <header style={{"width": "100%", marginTop: '25px', marginBottom: '25px'}}>
            <TitleSpan
                text={"Am fost des întrebați"}
                class="faq"/>
        </header>
        <div style={{marginRight: '10px', marginLeft: '10px'}}>
            <CustomizedAccordions></CustomizedAccordions>
        </div>
    </div>

</div>

</>
)
    ;
}

export default App;
