import * as React from 'react';
import SmallTagContainer from "./SmallTagContainer";
import PathTagContainer from "./PathTagContainer";
import line1 from "../../assets/cumfacem/line1.png";
import cumfacem from "../../assets/cumfacem/cumfacem_new.png";
import line2 from "../../assets/cumfacem/line2.png";
import line3 from "../../assets/cumfacem/line3.png";

export default function BookClubDiagramSection() {
    return (
        <>
            <div className="flexing">
                <SmallTagContainer
                    spanContent="1"
                    content="Colaborăm cu grădinița ta pentru a crea micro-biblioteci în funcție de vârstele copiilor abonați."
                    boldContent="Colaborăm"
                    widthPx="335px"
                    heightPx="232px"
                    topTextPct="30%"
                />

                <PathTagContainer
                    imgSrc={line1}
                />

                <SmallTagContainer
                    spanContent="2"
                    content="Selectăm cărțile conform unui plan de lectură și livrăm pachetele de cărți direct la grădinița ta."
                    boldContent="Selectăm cărțile"
                    widthPx="335px"
                    heightPx="232px"
                    topTextPct="30%"
                    topMargin="5vw"
                />
            </div>

            <div className="middle">
                <div className="emptySpace35">
                </div>
                <div className="emptySpace30">
                    <img src={cumfacem} style={{right: 0, width: "350px"}} className="rotate90"/>
                </div>
                <div style={{
                    zIndex: 1,
                    float: "right"
                }}>
                    <img src={line2} className="rotate90"/>
                </div>
            </div>


            <div className="flexing-reverse" style={{ width:"100%"}}>

                <SmallTagContainer
                    spanContent="3"
                    content="Iei acasă 4 cărți lunar. Le citești și să le explorezi cu cel mic, iar platforma îți arată când și cu cine vei face schimbul. "
                    boldContent="Iei acasă 4 cărți lunar"
                    widthPx="335px"
                    heightPx="232px"
                    topTextPct="30%"
                    // marginLeft="-5%"
                />
                <SmallTagContainer
                    spanContent="4"
                    content="Primești acces în platformă unde, pentru cărțile primite, găsești resurse educaționale: întrebări menite să susțină citirea interactivă și să pornească dialoguri interesante, precum și sugestii de activități inspirate din cărți."
                    boldContent="Primești acces în platformă"
                    widthPx="335px"
                    heightPx="332px"
                    topTextPct="23%"
                    topMargin="2vw"
                />

                <SmallTagContainer
                    spanContent="5"
                    content="La finalul abonamentului, cărțile din micro-bibliotecă rămân în grădiniță, iar copilul tău se bucură în continuare de ele."
                    boldContent="cărțile din micro-bibliotecă rămân în grădiniță"
                    widthPx="335px"
                    heightPx="232px"
                    topTextPct="30%"

                    topMargin="-10vw"
                />
            </div>

            <div className="middle" style={{marginTop:"-24vh"}}>
                <PathTagContainer
                    imgSrc={line1}
                />

                <PathTagContainer
                    imgSrc={line3}
                />
            </div>
        </>
    );
}
