import '../App.css';
import {Box, Typography, useMediaQuery} from "@mui/material";
import {Link} from "react-router-dom";
import {
    ArrowBack,
    ArrowForward,
    LibraryBooksRounded,
} from "@mui/icons-material";
import {Button} from "react-bootstrap";
import {IBookThumbnail} from "../services/model/Books";
import AddToBagComponent from "../core/components/AddToBag";
import React, {useEffect, useState} from "react";
import { useSwipeable } from "react-swipeable";

type Props = {
    moreRequest: string,
    ageLabel: string,
    cta: string,
    books: IBookThumbnail[],
    visibleItems: number
}
const BookStoreCollection = (props: Props) => {
    const {books, visibleItems} = props;
    const [itemsToShow, setItemsToShow] = useState(visibleItems);

    // Responsive adjustments
    const isSmallScreen = useMediaQuery("(max-width: 600px)"); // Mobile
    const isMediumScreen = useMediaQuery("(max-width: 960px)"); // Tablet
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (isSmallScreen) {
            setItemsToShow(2.5);
        } else if (isMediumScreen) {
            setItemsToShow(3.5);
        } else {
            setItemsToShow(visibleItems+0.5);
        }
    }, [isSmallScreen, isMediumScreen, visibleItems]);



    // Handle left/right navigation
    const handlePrev = () => {
        setCurrentIndex((prev) =>
            prev === 0 ? books.length+1 - itemsToShow : prev - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prev) =>
            prev + itemsToShow >= books.length+1 ? 0 : prev + 1
        );
    };

    // Swipe Support for Mobile
    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
    });

    return (
        <div className="book-group"><b></b>
            <div className="book-group-header">
                <h2 style={{float: "left"}}  className="book-category-title">
                    <strong>
                        <Link to={props.moreRequest}>
                            {props.ageLabel}
                        </Link>
                    </strong>

                </h2>

                <div style={{float: "right"}}>
                    <Button className="navigate-back-button"
                            onClick={handlePrev}
                    >
                        <ArrowBack sx={{color: "white"}}/>
                    </Button>

                    {/* Right Arrow */}
                    <Button className="navigate-back-button"
                            onClick={handleNext}
                    >
                        <ArrowForward sx={{color: "white"}}/>
                    </Button>
                    <div style={{clear: "both"}}></div>
                </div>

            </div>
            <Box
                {...handlers}
                sx={{
                    position: "relative",
                    width: "100%",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        transition: "transform 0.3s ease-in-out",
                        transform: `translateX(-${(currentIndex * 120) / itemsToShow}%)`,
                        width: "100%",
                    }}
                >
                    {books.map((book, index) => (
                        <Box
                            key={book.id || index}
                            sx={{
                                flex: `0 0 calc(100% / ${itemsToShow})`,
                                textAlign: "center",
                            }}
                        >
                            <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                                <Link to={`/books/details/` + book.slug}>
                                    {book.photoUrl ? (
                                        <Box key={book.photoUrl} sx={{
                                            marginBottom: 2,
                                            filter: 'drop-shadow(rgba(205, 180, 219, 0.5) 20px 30px 10px)',
                                            display: 'block'
                                        }}>
                                            <img className='bookInPlan' src={book.photoUrl}
                                                 alt={book.name} style={{
                                                maxHeight: '210px',
                                                maxWidth: '250px',
                                                objectFit: 'cover',
                                                minHeight: '200px'
                                            }}/>
                                            {book.hasResources ?
                                                <LibraryBooksRounded sx={{
                                                    color: "var(--cefacem-color)",
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    zIndex: 3
                                                }}/>

                                                :
                                                <></>
                                            }
                                        </Box>

                                    ) : (
                                        <Typography variant="body2">No photos
                                            available</Typography>
                                    )}
                                </Link>

                                <div className="fauna-one-regular"
                                     style={{
                                         fontSize: '.75em',
                                         opacity: '.7',
                                         paddingBottom: '5px'
                                     }}
                                >
                                    CARTE COPII {book.startAge === 0 ? '6+ LUNI' : `${book.startAge}+ ANI`}
                                </div>
                                <h3 className="book-title">{book.name}</h3>

                                <AddToBagComponent isDetailsPage={false} book={book}/>

                            </div>
                        </Box>
                    ))}
                    <Box
                        sx={{
                            flex: `0 0 calc(100% / ${itemsToShow})`,
                            textAlign: "center",
                        }}
                    >
                        <div className="discover-more">
                            <Link to={props.moreRequest}>
                                {props.cta}
                            </Link>
                        </div>
                    </Box>
                </Box>


            </Box>
        </div>
    );
}

export default BookStoreCollection;