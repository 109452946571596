import '../App.css';
import BookClubDiagramSection from "../core/components/BookClubDiagramSection";
import ActionSection from "../core/components/ActionSection";
import React, {useEffect} from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import {DoneOutlined} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet-async";

function BookClubs() {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[])
    return (
        <>
            <Helmet>
                <title>Cluburi pentru lectură în familie – Raise a Reader</title>
                <meta
                    name="description"
                    content="Descoperă cluburile noastre pentru lectură în familie – titluri alese cu grijă, sugestii de dialog și activități pentru momente speciale de conectare acasă."
                />
                <link rel="canonical" href="https://www.raiseareader.ro/book-clubs" />
            </Helmet>

            <div className="App">
                <div id="wave" className="parent align-left top-section div-with-background">
                    <div className="small-container ">
                        <br/>
                        <h1>Cluburile RaiseAReader sunt gândite special pentru încurajarea lecturii în familie și pentru a te ajuta să crești mai ușor un
                            copil cititor!</h1><br/>

                        <h5>Conectează-te cu alți părinți din grădinița ta și participă la schimburile de cărți pe care
                            le facilităm. </h5>
                        <h5>Nu trebuie să faci nimic decât să te abonezi la clubul potrivit copilului tău.
                            Iar de restul ne ocupăm noi!</h5>
                    </div>
                </div>
                <div className="gradient-wave-bg" style={{marginTop: '-30px'}}></div>
                <div className="App-body">
                    <div id="cumfacem" className="parent">


                        <BookClubDiagramSection/>

                        <section className="span-minor-text-without-padding align-left small-container padding-top">
                            În fiecare lună, copilul tău va primi:
                            <List>
                                <ListItem disablePadding className="align-items-flex-start">
                                    <DoneOutlined/>
                                    <ListItemText style={{paddingLeft: "7px"}}>
                                        <strong>O carte care stimulează dezvoltarea cognitivă.</strong> <br/>
                                        În funcție de vârstă, ajutăm copiii să învețe cum să numere, să descopere corpul
                                        uman și să exploreze
                                        subiecte fascinante precum natura, vremea, muzica, arta și planeta noastră.
                                        Aceste cărți sunt alese pentru a-i ajuta pe cei mici să înțeleagă mai profund
                                        lumea în care trăim și să învețe mai ușor diferite concepte.
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding className="align-items-flex-start">
                                    <DoneOutlined/>
                                    <ListItemText style={{paddingLeft: "7px"}}>
                                        <strong>O carte pentru dezvoltarea emoțională și personală.</strong> <br/>
                                        Oferim povești care să explice emoții, să-i învețe să îți exprime sentimentele,
                                        să împartă, să ajute, să înțeleagă importanța respectării regulilor și a
                                        rutinei, să fie politicoși.
                                        Sunt cărți care stimulează încrederea în sine și încurajează obiceiurile bune.
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding className="align-items-flex-start">
                                    <DoneOutlined/>
                                    <ListItemText style={{paddingLeft: "7px"}}>
                                        <strong>O carte care încurajează creativitatea.</strong> <br/>
                                        Alegem povești deosebite, care stimulează imaginația, încurajează gândirea
                                        critică și oferă o varietate de ilustrații.
                                        Poveștile creative îi încurajează pe copii să fie ingenioși, să exploreze
                                        perspective și idei noi.
                                    </ListItemText>
                                </ListItem>
                                <ListItem disablePadding className="align-items-flex-start">
                                    <DoneOutlined/>
                                    <ListItemText style={{paddingLeft: "7px"}}>
                                        <strong>O carte în limba engleză.</strong> <br/>
                                        Expunerea timpurie la o limbă străină îi ajută pe copii să absoarbă noi limbi în
                                        mod natural,
                                        îmbunătățind memoria și perfecționând abilitățile de ascultare.
                                        Punem bazele învățării unei limbi străine într-o formă lejeră și captivantă.
                                    </ListItemText>
                                </ListItem>
                                {/*<ListItem disablePadding className="align-items-flex-start">*/}
                                {/*    <DoneOutlined/>*/}
                                {/*    <ListItemText style={{paddingLeft: "7px"}}>*/}
                                {/*        O carte din <span className="low-highlight-purple">cărțile comune</span> -*/}
                                {/*        acestea includ mici enciclopedii, cărți din serii iubite de copii sau cărți de*/}
                                {/*        parenting.*/}
                                {/*        Acestea îți vin în sprijin atunci când copilul tău e curios să exploreze în profunzime anumite*/}
                                {/*        teme sau e foarte pasionat de o anumită colecție.*/}
                                {/*        De asemenea, ne-am gândit și la tine, știm că a fi părinte vine cu multe*/}
                                {/*        dificultăți, așa că îți punem la dispoziție cele mai bune cărți de parenting.*/}
                                {/*    </ListItemText>*/}
                                {/*</ListItem>*/}
                            </List>
                        </section>

                    </div>
                </div>

                {/*<div style={{margin: "20px"}}>*/}
                {/*    <div>Cluburile se formează din 4 până la 12 membri. Abonamentul se încheie pe o perioadă de la 4*/}
                {/*        până la 12 luni, în functie de nunărul membrilor.*/}
                {/*    </div>*/}
                {/*    <div style={{backgroundColor: 'var(--aventura-dark-color)', color: 'white'}}>Pentru un grup*/}
                {/*        de <strong>6 persoane, ai acces la 24 de cărți pe o perioadă de 6 luni</strong>. Valoarea*/}
                {/*        cărților primite este de aproximativ <strong>1200*/}
                {/*            lei</strong>.*/}
                {/*    </div>*/}
                {/*    <div style={{backgroundColor: 'var(--aventura-color)', color: 'var(--aventura-dark-color)'}}>Pentru*/}
                {/*        un grup de <strong>8 persoane, ai acces la 32 de cărți pe o perioadă de 8 luni</strong>.*/}
                {/*        Valoarea cărților primite este de aproximativ <strong>1600*/}
                {/*            lei</strong>.*/}
                {/*    </div>*/}
                {/*</div>*/}

                <ActionSection/>

                <div className="gradient-wave-bg-orange flip"
                     style={{marginTop: '-15px', transform: 'rotate(180deg)'}}></div>
                <div className="parent align-left top-section orange-background " style={{marginTop: '-5px'}}>
                    <div className="span-minor-text-without-padding align-left small-container">
                        <br/>
                        <h2>În plus, găsești pe platformă resurse adiționale pentru lecturi interactive: <strong>sugestii
                            de dialog și idei de activități și joacă pe baza cărților citite.</strong></h2>

                        <h5> Citirea interactivă, așa cum arată și cercetările, este o modalitate grozavă de a
                            îmbunătăți limbajul, înțelegerea și abilitățile de gândire critică ale copilului tău.</h5>
                        <h5>
                            Adresând întrebări înainte, în timpul și după citire, îți implici copilul în conversații
                            interesante care îmbunătățesc vocabularul, dezvoltă logica și gândirea critică și stimulează
                            dragostea pentru învățare. </h5>

                        <section className="span-minor-text align-left">
                            <Link to="/books/details/mamaaa">
                                <div className="button button-contact button-contact-orange"
                                     style={{marginLeft: '10px', width: "fit-content"}}>
                                    <span>Vezi aici un exemplu</span>
                                </div>
                            </Link>
                        </section>

                    </div>
                </div>
                <div className="gradient-wave-bg-orange" style={{marginTop: '-10px'}}></div>
            </div>
        </>
    );
}

export default BookClubs;
