import React from 'react';
import { Helmet } from 'react-helmet-async';
import {Book} from "./model/Books";
import {sanitizeMetaDescription} from "./seoService";

type SEOProps = {
    book: Book;
};

const SEO: React.FC<SEOProps> = ({ book }) => {
    const author = book.authors?.[0]?.name || '';
    const title = `${book.name} de ${author}`;

    const rawDescription = book.description?.trim() || 'Carte educativă pentru copii disponibilă pe Raise a Reader.';
    const description = sanitizeMetaDescription(rawDescription);

    const url = `https://www.raiseareader.ro/books/details/${book.slug}`;
    const image = book.photos?.find(photo => photo.main)?.url || '';

    const salePrice = book.salePrice?.toFixed(2);
    const catalogPrice = book.catalogPrice?.toFixed(2);
    const availability = book.soldOut ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock';

    const jsonLd = {
        "@context": "https://schema.org/",
        "@type": "Product",
        name: book.name,
        image: [image],
        description: description,
        "url": url,
        sku: book.isbn || "",
        brand: {
            "@type": "Brand",
            name: book.publisher?.name || 'Raise a Reader'
        },
        offers: {
            "@type": "Offer",
            url,
            priceCurrency: "RON",
            price: salePrice,
            availability,
            itemCondition: "https://schema.org/NewCondition",
            priceSpecification: [
                {
                    "@type": "UnitPriceSpecification",
                    price: catalogPrice,
                    priceCurrency: "RON",
                    name: "Preț întreg"
                },
                {
                    "@type": "UnitPriceSpecification",
                    price: salePrice,
                    priceCurrency: "RON",
                    name: "Preț redus"
                }
            ]
        }
    };

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <link rel="canonical" href={url}/>

            {/* Open Graph for social media */}
            <meta property="og:title" content={title}/>
            <meta property="og:description" content={description}/>
            <meta property="og:type" content="book"/>
            <meta property="og:url" content={url}/>
            <meta property="og:site_name" content='raiseareader.ro'/>
            <meta property="og:image" content={image}/>
            <meta property="og:image:alt" content="Citim împreună, creștem împreună"/>
            {salePrice && <meta property="product:price:amount" content={salePrice}/>}
            <meta property="product:price:currency" content="RON"/>

            {/* Structured data for Google Rich Snippets */}
            <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        </Helmet>
    );
};

export default SEO;
